import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: localStorage.getItem('preferredLang') || 'en', // default language from localStorage
  translations: null,  // translations will be loaded asynchronously
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      const { language, translations } = action.payload;
      state.language = language;
      state.translations = translations;
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;

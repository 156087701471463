import { apiRequest } from '../../../services/api/apiUtility';
import { API_ENDPOINTS } from '../../../services/api/config';
import { parseISO, format } from 'date-fns';

/**
 * Menu-related API functions
 */

export const getMenuInsights = async (locationId, offset = 0, limit = 50, token, dateFilter = null) => {
  try {
    // Build base URL
    let url = API_ENDPOINTS.LOCATION.MENU_INSIGHTS.PAGINATED(locationId, offset, limit);

    // Add date parameters only if dateFilter is provided and has both dates
    if (dateFilter?.startDate && dateFilter?.endDate) {
      const startDate = format(parseISO(dateFilter.startDate), "yyyy-MM-dd");
      const endDate = format(parseISO(dateFilter.endDate), "yyyy-MM-dd");
      url += `?startDate=${startDate}&endDate=${endDate}`;
    }

    return await apiRequest(
      url,
      "GET",
      null,
      {},  // Empty params since we're adding dates to URL
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error("Error fetching menu insights:", error);
    throw error;
  }
};

/**
 * Fetch detailed information for a menu item by its review ID
 * @param {string} locationId - The location ID
 * @param {string} reviewId - The review ID
 * @param {string} token - Auth token
 * @returns {Promise<Object>} - The menu item details response
 */
export const getMenuItemDetails = async (locationId, reviewId, token) => {
  try {
    return await apiRequest(
      API_ENDPOINTS.LOCATION.REVIEWS.GET_BY_ID(locationId, reviewId),
      "GET",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error("Error fetching menu item details:", error);
    throw error;
  }
};

// Add additional menu-related API functions here
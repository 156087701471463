import React, { useState, useEffect } from "react";
import { useAccountSettings } from "../hooks/useAccountSettings";
import { Bell, Mail, Loader2, Check, X } from "lucide-react";
import { useLanguage } from "../../language/hooks/useLanguageHook";

const NotificationsTab = () => {
  const { alertConfig, loading, error, updateUserAlertConfig } =
    useAccountSettings();
  const { t } = useLanguage();
  const [isSaving, setIsSaving] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (alertConfig?.email) {
      setEmail(alertConfig.email);
      setIsValidEmail(true);
    }
  }, [alertConfig]);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const handleEmailSave = async () => {
    if (!isValidEmail || !email) return;

    setIsSaving(true);
    const success = await updateUserAlertConfig({
      ...alertConfig,
      email: email,
    });
    setIsSaving(false);
    if (success) {
      setIsEditing(false);
    }
  };

  const handleToggle = async (field) => {
    if (loading || isSaving) return;

    setIsSaving(true);
    const success = await updateUserAlertConfig({
      ...alertConfig,
      [field]: !alertConfig[field],
    });
    setIsSaving(false);
  };

  return (
    <div className="space-y-6 p-4">
      <div className="space-y-1">
        <h3 className="text-lg font-medium">
          {t("dashboard.accountSettings.notifications.header")}
        </h3>
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Configure how you want to receive alerts and notifications
        </p>
      </div>

      {/* Email Input Section */}
      <div className="space-y-2">
        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {t("dashboard.accountSettings.notifications.notificationEmail")}
        </label>
        <div className="flex items-center space-x-2">
          <div className="relative flex-1">
            <input
              type="email"
              value={loading ? "" : email || alertConfig?.email || ""}
              onChange={handleEmailChange}
              disabled={!isEditing || loading}
              className={`w-full p-2 pr-10 border rounded-lg transition-colors
                ${
                  !isValidEmail && isEditing
                    ? "border-red-500"
                    : "border-gray-300"
                }
                ${
                  !isEditing || loading
                    ? "bg-gray-50 dark:bg-gray-800"
                    : "bg-white dark:bg-gray-700"
                }
                dark:border-gray-600 dark:text-white
                focus:ring-2 focus:ring-blue-500 outline-none
                ${loading ? "animate-pulse" : ""}`}
              placeholder={loading ? "Loading..." : "Enter your email address"}
            />
            {isEditing && !loading && (
              <div className="absolute right-3 top-1/2 -translate-y-1/2">
                {isValidEmail && email ? (
                  <Check className="h-4 w-4 text-green-500" />
                ) : (
                  <X className="h-4 w-4 text-red-500" />
                )}
              </div>
            )}
          </div>
          {isEditing ? (
            <button
              onClick={handleEmailSave}
              disabled={!isValidEmail || !email || isSaving || loading}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors
                ${
                  isValidEmail && email && !loading
                    ? "bg-blue-600 hover:bg-blue-700 text-white"
                    : "bg-gray-200 text-gray-500 cursor-not-allowed"
                }
                disabled:opacity-50`}
            >
              {isSaving ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                t("dashboard.accountSettings.notifications.save")
              )}
            </button>
          ) : (
            <button
              onClick={() => setIsEditing(true)}
              disabled={loading}
              className={`px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700 dark:text-blue-400
                ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
            >
              {t("dashboard.accountSettings.notifications.edit")}
            </button>
          )}
        </div>
        {!isValidEmail && isEditing && (
          <p className="text-sm text-red-500">
            {t("dashboard.accountSettings.notifications.invalidEmail")}
          </p>
        )}
      </div>

      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Bell className="h-5 w-5 text-gray-500" />
            <div>
              <div className="font-medium">
                {t(
                  "dashboard.accountSettings.notifications.inAppNotifications"
                )}
              </div>
              <div className="text-sm text-gray-500">
                Receive alerts within the dashboard
              </div>
            </div>
          </div>
          <button
            disabled={loading || isSaving}
            onClick={() => handleToggle("client_alerts_enabled")}
            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors 
              ${
                loading
                  ? "bg-gray-200 dark:bg-gray-700"
                  : alertConfig?.client_alerts_enabled
                  ? "bg-blue-600"
                  : "bg-gray-200 dark:bg-gray-700"
              } ${loading ? "animate-pulse" : ""}`}
          >
            <span
              className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                alertConfig?.client_alerts_enabled
                  ? "translate-x-6"
                  : "translate-x-1"
              }`}
            />
          </button>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Mail className="h-5 w-5 text-gray-500" />
            <div>
              <div className="font-medium">
                {t(
                  "dashboard.accountSettings.notifications.emailNotifications"
                )}
              </div>
              <div className="text-sm text-gray-500">
                Receive alerts via email
              </div>
            </div>
          </div>
          <button
            disabled={loading || isSaving || !email || !isValidEmail}
            onClick={() => handleToggle("email_alerts_enabled")}
            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors 
              ${
                loading
                  ? "bg-gray-200 dark:bg-gray-700"
                  : alertConfig?.email_alerts_enabled
                  ? "bg-blue-600"
                  : "bg-gray-200 dark:bg-gray-700"
              } ${
              !email || !isValidEmail ? "opacity-50 cursor-not-allowed" : ""
            }
              ${loading ? "animate-pulse" : ""}`}
          >
            <span
              className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                alertConfig?.email_alerts_enabled
                  ? "translate-x-6"
                  : "translate-x-1"
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationsTab;

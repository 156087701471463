import { createSlice } from "@reduxjs/toolkit";
import { loginUser, refreshAuth, logout } from "./authThunks";

const initialState = {
  accessToken: null,
  userRole: null,
  userUsername: null,
  userEmail: null,
  userFirstName: null,
  userLastName: null,
  userLocationId: null,
  userId: null,
  isLoading: false,
  error: null,
  authorizedLocations: [],
  selectedRestaurant: null,
  subscriptionTier: null,
  accessTokenExpiresIn: null,
  authInitialized: false,
  refreshTokenTimeoutId: null,
  lastRefreshTime: null,
  userEnabled: true,
  userRoleId: null
};



const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSelectedRestaurant: (state, action) => {
      state.selectedRestaurant = action.payload;
    },
    setRefreshTokenTimeoutId: (state, action) => {
      // Clear existing timeout if any
      if (state.refreshTokenTimeoutId) {
        clearTimeout(state.refreshTokenTimeoutId);
      }
      state.refreshTokenTimeoutId = action.payload;
    },
    clearAuth: (state) => {
      // Clear timeout if exists
      if (state.refreshTokenTimeoutId) {
        clearTimeout(state.refreshTokenTimeoutId);
      }
      return { ...initialState, authInitialized: true };
    },
    setAuthInitialized: (state, action) => {
      state.authInitialized = action.payload;
    },
    updateUserProfile: (state, action) => {
      const { firstname, lastname, email, username } = action.payload;
      state.userFirstName = firstname;
      state.userLastName = lastname;
      state.userEmail = email;
      state.userUsername = username;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login cases
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          error: null,
          authInitialized: true,
          lastRefreshTime: Date.now()
        };
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Refresh cases
      .addCase(refreshAuth.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(refreshAuth.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          error: null,
          authInitialized: true
        };
      })
      .addCase(refreshAuth.rejected, (state, action) => {
        return {
          ...initialState,
          error: action.payload,
          authInitialized: true
        };
      })
      // Logout cases
      .addCase(logout.fulfilled, (state) => {
        return { ...initialState, authInitialized: true };
      });
  },
});

// Actions
export const { 
  setSelectedRestaurant, 
  setRefreshTokenTimeoutId,
  clearAuth,
  setAuthInitialized,
  updateUserProfile
} = authSlice.actions;

export default authSlice.reducer;
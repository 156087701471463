import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';
import logoImage from '../../assets/images/FeedBacked4_NBG.png';
import LanguageSelector from '../../features/language/components/LanguageSelector';


const NavItem = ({ to, children, onClick, isMobile }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  if (isMobile) {
    return (
      <Link
        to={to}
        onClick={onClick}
        className={`
          block w-full px-4 py-3 
          text-gray-700
          hover:text-black
          border-b border-gray-100
          ${isActive ? 'text-blue-600' : ''}
        `}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      className={`
        px-4 py-2 text-gray-700
        hover:text-black
        transition-colors relative group
        ${isActive ? 'text-black font-semibold' : ''}
      `}
    >
      {children}
      <span
        className={`
          absolute bottom-0 left-0 w-full h-0.5 
          bg-blue-600 transform scale-x-0 
          group-hover:scale-x-100 transition-transform duration-300
          ${isActive ? 'scale-x-100' : ''}
        `}
      />
    </Link>
  );
};

const Logo = ({ className = "h-8 w-8" }) => (
  <img 
    src={logoImage} 
    alt="FeedBacked Logo" 
    className={`${className} object-contain`}
  />
);

const MainPageNavbar = ({ brand, links, isDashboard = false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const navbarClasses = `
    fixed top-0 left-0 right-0 
    ${isDashboard ? 'md:left-64' : ''}
    z-50 px-6 py-4
    bg-white/80 backdrop-blur-md
    shadow-lg
    transition-all duration-200
  `;

  const mobileMenuClasses = `
    fixed inset-0 bg-white/95 backdrop-blur-sm z-50
    flex flex-col
    transform transition-transform duration-300
    ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}
  `;

  return (
    <>
      <nav className={navbarClasses}>
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          {/* Logo and Brand */}
          <Link 
            to="/" 
            className="flex items-center space-x-3 text-black hover:opacity-90 transition-opacity"
          >
            <Logo />
            <span className="font-semibold text-xl md:text-2xl text-blue-600 tracking-wide">
              {brand}
            </span>
          </Link>

          {/* Desktop Navigation: increased spacing between nav items */}
          <div className="hidden md:flex items-center space-x-6">
            {links.map((link) => (
              <NavItem key={link.to} to={link.to}>
                {link.label}
              </NavItem>
            ))}
          </div>

          <div className="flex items-center space-x-4">
            {/* Language Selector (Desktop) */}
            <div className="hidden md:inline-flex items-center">
              <LanguageSelector />
            </div>

            {/* Updated Login/CTA Button: more padding, a stronger shadow, and a subtle scale on hover */}
            <Link
              to="/login"
              className="hidden md:inline-flex items-center px-6 py-3 
                text-base rounded-md 
                bg-blue-600 text-white
                border border-transparent 
                hover:bg-blue-700 
                transition-all duration-200
                shadow-md hover:shadow-lg transform hover:scale-105"
            >
              Log In
            </Link>

            {/* Mobile Menu Button remains */}
            <button
              onClick={() => setIsMenuOpen(true)}
              className="md:hidden p-2"
            >
              <span className="sr-only">Open menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      <div className={mobileMenuClasses}>
        <div className="flex items-center justify-between p-4 border-b border-gray-100">
          <span className="font-bold text-xl text-blue-600">
            {brand}
          </span>
          <button
            onClick={() => setIsMenuOpen(false)}
            className="p-2"
          >
            <X size={24} />
          </button>
        </div>
        
        <div className="flex flex-col">
          {links.map((link) => (
            <NavItem 
              key={link.to} 
              to={link.to} 
              onClick={() => setIsMenuOpen(false)}
              isMobile
            >
              {link.label}
            </NavItem>
          ))}
        </div>

        <Link
          to="/login"
          onClick={() => setIsMenuOpen(false)}
          className="mt-auto mx-4 mb-4 py-3 px-4
            text-center rounded-md 
            bg-blue-600 text-white
            hover:bg-blue-700 
            transition-colors duration-200
            shadow-md hover:shadow-lg transform hover:scale-105"
        >
          Log In
        </Link>
      </div>
    </>
  );
};

MainPageNavbar.propTypes = {
  brand: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  isDashboard: PropTypes.bool,
};

export default MainPageNavbar;
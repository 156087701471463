import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// Month names for formatting
const MONTH_NAMES_EN = [
  'January', 'February', 'March', 'April', 'May', 'June', 
  'July', 'August', 'September', 'October', 'November', 'December'
];

const MONTH_NAMES_FR = [
  'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
];

/**
 * Add ordinal suffix to a number (1st, 2nd, 3rd, etc.)
 * @param {number} num - Number to add suffix to
 * @returns {string} - Number with ordinal suffix
 */
export const getOrdinalSuffix = (num) => {
  const j = num % 10, k = num % 100;
  if (j === 1 && k !== 11) return `${num}st`;
  if (j === 2 && k !== 12) return `${num}nd`;
  if (j === 3 && k !== 13) return `${num}rd`;
  return `${num}th`;
};

/**
 * Extract date parts from ISO date string without timezone conversion
 * @param {string} dateString - ISO date string (e.g. "2025-03-08T00:00:00Z")
 * @returns {Object|null} - Object with year, month, day properties or null
 */
export const extractDateParts = (dateString) => {
  if (!dateString) return null;
  
  try {
    // Extract YYYY-MM-DD part from ISO string
    const datePart = dateString.split('T')[0];
    if (!datePart) return null;
    
    const [year, month, day] = datePart.split('-').map(num => parseInt(num, 10));
    return { year, month, day };
  } catch (error) {
    console.error('Error extracting date parts:', error);
    return null;
  }
};

/**
 * Format a date string in display format with proper localization
 * @param {string} dateString - ISO date string
 * @param {string} locale - Language locale ('en' or 'fr')
 * @param {string} format - Format type ('simple' for MM/DD/YYYY or 'full' for month name formatting)
 * @returns {string} - Formatted date string
 */
export const formatDisplayDate = (dateString, locale = 'en', formatType = 'full') => {
  if (!dateString) return '';
  
  try {
    const dateParts = extractDateParts(dateString);
    if (!dateParts) return dateString;
    
    const { year, month, day } = dateParts;
    
    // Simple format (MM/DD/YYYY or DD/MM/YYYY)
    if (formatType === 'simple') {
      return locale === 'fr' 
        ? `${day}/${month}/${year}` // French: DD/MM/YYYY
        : `${month}/${day}/${year}`; // English: MM/DD/YYYY
    }
    
    // Full format with month names
    if (locale === 'fr') {
      // French: day month year (2 Janvier 2025)
      return `${day} ${MONTH_NAMES_FR[month-1]} ${year}`;
    } else {
      // English: month day, year (January 2nd, 2025)
      return `${MONTH_NAMES_EN[month-1]} ${getOrdinalSuffix(day)}, ${year}`;
    }
  } catch (error) {
    console.error('Error formatting display date:', error);
    return dateString;
  }
};

/**
 * Format a date for API (YYYY-MM-DD)
 * @param {Date|string} date - Date object or string to format
 * @returns {string} - Date in YYYY-MM-DD format
 */
export const formatApiDate = (date) => {
  if (!date) return '';
  
  try {
    if (typeof date === 'string' && date.includes('T')) {
      // For ISO strings, just return the date part
      return date.split('T')[0];
    }
    
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    
    // If it's already in YYYY-MM-DD format
    if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
      return date;
    }
    
    // Fallback to date-fns format
    return format(new Date(date), 'yyyy-MM-dd');
  } catch (error) {
    console.error('Error formatting API date:', error);
    return '';
  }
};

/**
 * Check if a date string is within a date range
 * @param {string} dateStr - Date string to check (ISO format)
 * @param {Object} dateRange - Date range object
 * @param {string} dateRange.startDate - Start date in YYYY-MM-DD format
 * @param {string} dateRange.endDate - End date in YYYY-MM-DD format
 * @returns {boolean} - True if the date is within the range
 */
export const isDateInRange = (dateStr, { startDate, endDate }) => {
  try {
    // Extract date parts without timezone conversion
    const datePart = dateStr.split('T')[0];
    const start = startDate.split('T')[0];
    const end = endDate.split('T')[0];
    
    // Simple string comparison (works for ISO dates YYYY-MM-DD)
    return datePart >= start && datePart <= end;
  } catch (error) {
    console.error('Error checking if date is in range:', error);
    return false;
  }
};

/**
 * Parse a UTC date string to a Date object 
 * @param {string} dateString - ISO date string
 * @returns {Date|null} - Date object in UTC or null if invalid
 */
export const parseUtcDate = (dateString) => {
  if (!dateString) return null;
  
  try {
    const parts = extractDateParts(dateString);
    if (!parts) return null;
    
    const { year, month, day } = parts;
    return new Date(Date.UTC(year, month - 1, day));
  } catch (error) {
    console.error('Error parsing UTC date:', error);
    return null;
  }
};

export default {
  formatDisplayDate,
  formatApiDate,
  isDateInRange,
  parseUtcDate,
  getOrdinalSuffix,
  extractDateParts
}; 
import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { X, Bell, User, Palette, Building2 } from 'lucide-react';
import { ThemeContext } from '../../../contexts/ThemeContext';
import NotificationsTab from './NotificationsTab';
import ProfileTab from './ProfileTab';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useLanguage } from '../../language/hooks/useLanguageHook';

const AccountSettingsModal = ({ isOpen, onClose }) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useLanguage();
  const isDark = theme === 'dark';
  const [activeTab, setActiveTab] = useState('profile');
  const isMobile = useMediaQuery('(max-width: 640px)');

  if (!isOpen) return null;

  const tabs = [
    { id: 'profile', label: t('dashboard.accountSettings.tabs.profile'), icon: User },
    { id: 'notifications', label: t('dashboard.accountSettings.tabs.notifications'), icon: Bell },
    { id: 'appearance', label: t('dashboard.accountSettings.tabs.appearance'), icon: Palette },
    { id: 'locations', label: t('dashboard.accountSettings.tabs.locations'), icon: Building2 },
  ];


  const modalContent = (
    <div 
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-[1000] backdrop-blur-sm p-4"
      onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}
    >
      <div 
        className={`w-full max-h-[90vh] overflow-y-auto rounded-lg shadow-xl 
          ${isDark ? "bg-gray-900 border border-gray-700" : "bg-white"}
          transform transition-all duration-200
          relative
          sm:max-w-2xl
          ${isMobile ? 'h-[100vh] max-h-[100vh] m-0 rounded-none' : 'h-auto'}`}
      >
        {/* Header */}
        <div className={`flex items-center justify-between p-4 border-b sticky top-0 z-10 ${
          isDark ? "border-gray-700 bg-gray-900" : "border-gray-200 bg-white"
        }`}>
          <h2 className={`text-xl font-semibold ${
            isDark ? "text-gray-100" : "text-gray-900"
          }`}>
            {t('dashboard.accountSettings.header')}
          </h2>
          <button
            onClick={onClose}
            className={`p-2 rounded-full transition-colors ${
              isDark 
                ? "text-gray-400 hover:text-gray-300 hover:bg-gray-800" 
                : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
            }`}
          >
            <X size={20} />
          </button>
        </div>

        {/* Tabs */}
        <div className={`flex ${isMobile ? 'overflow-x-auto' : ''} border-b ${
          isDark ? "border-gray-700" : "border-gray-200"
        }`}>
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`flex items-center px-4 py-2 border-b-2 whitespace-nowrap
                ${isMobile ? 'flex-1 justify-center text-sm' : ''}
                ${activeTab === tab.id
                  ? `${isDark ? "border-blue-500 text-blue-500" : "border-blue-600 text-blue-600"}`
                  : `border-transparent ${isDark ? "text-gray-400" : "text-gray-500"}`
                } hover:text-blue-600 dark:hover:text-blue-500 transition-colors`}
            >
              <tab.icon className={`w-4 h-4 ${isMobile ? 'mr-0' : 'mr-2'}`} />
              {!isMobile && tab.label}
            </button>
          ))}
        </div>

        {/* Content with mobile-friendly padding */}
        <div className={`${isMobile ? 'p-2' : 'p-4'}`}>
          {activeTab === 'profile' && <ProfileTab />} 
          {activeTab === 'notifications' && <NotificationsTab />}
          {activeTab === 'appearance' && <p>{t('dashboard.accountSettings.appearance.comingSoon')}</p>}
          {activeTab === 'locations' && <p>{t('dashboard.accountSettings.locations.comingSoon')}</p>}
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.body
  );
};

export default AccountSettingsModal;

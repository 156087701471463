import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  createUser, 
  updateUser,
  fetchUsers,
  selectRoles 
} from '../../../../store/shared/user/userSlice';
import { selectAccessToken } from '../../../../store/shared/auth/authSelectors';
import { selectLocations } from '../../../../store/shared/location/locationSelectors';
import { selectRestaurants } from '../../../../store/slices/restaurantSlice';
import UserRoleSelector from './UserRoleSelector';
import { toast } from 'sonner';

const UserForm = ({ user, onClose }) => {
  const dispatch = useDispatch();
  const roles = useSelector(selectRoles);
  const token = useSelector(selectAccessToken);
  const locations = useSelector(selectLocations);
  const restaurants = useSelector(selectRestaurants);
  
  const [formData, setFormData] = useState({
    username: user?.username || '',
    email: user?.email || '',
    firstname: user?.firstname || '',
    lastname: user?.lastname || '',
    roles: user?.roles ? user.roles.map(role => role.id || role) : [],
    location_id: user?.location_id || '',
    enabled: user?.enabled ?? true
  });

  // Update form data when user prop changes
  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username || '',
        email: user.email || '',
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        roles: user.roles ? user.roles.map(role => role.id || role) : [],
        location_id: user.location_id || '',
        enabled: user.enabled ?? true
      });
    }
  }, [user]);

  // Filter out the "All" location and prepare location options with restaurant names
  const locationOptions = locations
    .filter(location => location.name !== "All")
    .map(location => {
      const restaurant = restaurants.find(r => r.id === location.restaurant_id);
      return {
        ...location,
        displayName: `${location.name} (${restaurant?.name || 'Unknown Restaurant'})`
      };
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.username || !formData.email || !formData.firstname || 
        !formData.lastname || !formData.location_id || formData.roles.length === 0) {
      toast.error('Please fill in all required fields');
      return;
    }

    // Prepare the user data
    const userData = {
      username: formData.username.trim(),
      email: formData.email.trim(),
      firstname: formData.firstname.trim(),
      lastname: formData.lastname.trim(),
      location_id: formData.location_id,
      roles: formData.roles,
      enabled: formData.enabled === 'active' || formData.enabled === true
    };

    try {
      if (user) {
        await dispatch(updateUser({ id: user.id, userData, token })).unwrap();
        toast.success('User updated successfully');
      } else {
        await dispatch(createUser({ userData, token })).unwrap();
        toast.success('User created successfully');
      }
      // Refresh the users list
      await dispatch(fetchUsers(token));
      onClose();
    } catch (error) {
      console.error('Error submitting user:', error);
      toast.error(error.message || 'Failed to save user');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Username</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">First Name</label>
        <input
          type="text"
          name="firstname"
          value={formData.firstname}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Last Name</label>
        <input
          type="text"
          name="lastname"
          value={formData.lastname}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          required
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Location</label>
        <select
          name="location_id"
          value={formData.location_id}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          required
        >
          <option value="">Select Location</option>
          {locationOptions.map((location) => (
            <option key={location.id} value={location.id}>
              {location.displayName}
            </option>
          ))}
        </select>
      </div>

      <UserRoleSelector
        selectedRoles={formData.roles}
        onChange={(roles) => setFormData(prev => ({ ...prev, roles }))}
        availableRoles={roles}
      />

      <div>
        <label className="block text-sm font-medium text-gray-700">Status</label>
        <select
          name="enabled"
          value={formData.enabled === true ? 'active' : 'inactive'}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>

      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 border rounded-md"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-md"
        >
          {user ? 'Update' : 'Create'} User
        </button>
      </div>
    </form>
  );
};

export default UserForm; 
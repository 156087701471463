import React, { useCallback, useEffect, Suspense, useState } from 'react';
import { Shield, ArrowRight, Clock, CheckCircle, X } from 'lucide-react';

// Import images directly
import Image1 from '../../../../assets/images/1.png';
import Image2 from '../../../../assets/images/2.png';
import Image3 from '../../../../assets/images/3.png';
import Image4 from '../../../../assets/images/4.png';
import Image5 from '../../../../assets/images/5.png';
import Image6 from '../../../../assets/images/6.png';
import Image7 from '../../../../assets/images/7.png';
import Image8 from '../../../../assets/images/8.png';
import image9 from '../../../../assets/images/9.png';

// Create an image map
const images = {
  1: Image1,
  2: Image2,
  3: Image3,
  4: Image4,
  5: Image5,
  6: Image6,
  7: Image7,
  8: Image8,
  9: image9,
};

const ImageWithFallback = ({ step, alt, onImageClick }) => {
  return (
    <div className="mt-4 overflow-hidden rounded-lg border border-gray-200 bg-white dark:border-gray-700 dark:bg-gray-800">
      <div 
        className="relative aspect-[16/9] w-full cursor-pointer"
        onClick={() => onImageClick(step)}
      >
        <img
          src={images[step]}
          className="h-full w-full object-contain p-2"
          alt={alt}
          loading="lazy"
          onError={(e) => {
            e.target.onerror = null;
            e.target.classList.add('opacity-50');
          }}
        />
      </div>
    </div>
  );
};

const GoogleBusinessGuide = ({ isOpen, onClose }) => {
  // Add state for zoomed image
  const [zoomedImage, setZoomedImage] = useState(null);

  // Prevent body scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Handle click outside
  const handleClickOutside = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  // Handle image click
  const handleImageClick = (step) => {
    setZoomedImage(step);
  };

  // Handle zoom close
  const handleZoomClose = () => {
    setZoomedImage(null);
  };

  if (!isOpen) return null;

  return (
    <>
      <div 
        className="fixed inset-0 z-50 modal-overlay bg-black bg-opacity-50 flex items-start md:items-center justify-center p-2 md:p-4"
        onClick={handleClickOutside}
      >
        <div 
          className="relative bg-white dark:bg-gray-900 rounded-xl shadow-xl w-full max-h-[95vh] md:max-h-[85vh] overflow-y-auto"
          style={{ maxWidth: "min(100%, 48rem)" }}
          onClick={e => e.stopPropagation()}
        >
          {/* Close button */}
          <button
            onClick={onClose}
            className="fixed md:absolute right-3 top-3 p-2 bg-white rounded-full shadow-md md:shadow-none text-gray-400 hover:text-gray-600 transition-colors z-10"
            aria-label="Close modal"
          >
            <X size={24} />
          </button>

        {/* Content container */}
        <div className="p-4 md:p-6">
          {/* Header Section */}
          <div className="mb-6 md:mb-8 text-center">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-3 md:mb-4">
              How to Add FeedBacked as a Manager
            </h1>
            <p className="text-base md:text-lg text-gray-600">
              A step-by-step guide to grant FeedBacked access
            </p>
          </div>

          {/* Steps container with smaller padding on mobile */}
          <div className="space-y-3 md:space-y-4">
            {/* Each step container */}
            <div className="bg-gray-50 dark:bg-gray-800 p-4 md:p-6 rounded-lg">
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center flex-shrink-0">
                  1
                </div>
                <div className="flex-1">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">Log in to Google Business</h3>
                  <ul className="list-disc pl-5 space-y-2 text-gray-600 dark:text-gray-300">
                    <li>Visit <a href="https://business.google.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300">business.google.com</a> and sign in with the Google account that manages your business profile.</li>
                    <li>Once logged in, select <strong>Businesses</strong> from the left menu.</li>
                  </ul>
                  <ImageWithFallback step={1} alt="Google Business Profile login screen" onImageClick={handleImageClick} />
                </div>
              </div>
            </div>

            {/* Step 2 */}
            <div className="bg-gray-50 dark:bg-gray-800 p-4 md:p-6 rounded-lg">
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center flex-shrink-0">
                  2
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">Select your Business</h3>
                  <ul className="list-disc pl-5 space-y-2 text-gray-600 dark:text-gray-300">
                    <li>Locate the business you want us to manage from the list.</li>
                    <li>Click on the <strong>business name</strong> or select it to proceed.</li>
                  </ul>
                  <ImageWithFallback step={2} alt="Navigate to Users and Access screen" onImageClick={handleImageClick} />
                </div>
              </div>
            </div>

            {/* Step 3 */}
            <div className="bg-gray-50 dark:bg-gray-800 p-4 md:p-6 rounded-lg">
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center flex-shrink-0">
                  3
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">Open the Business Profile Settings</h3>
                  <p className="text-gray-600 dark:text-gray-300">On your business Google page, click the <strong>three-dot menu</strong> at the top-right corner.</p>
                  <ImageWithFallback step={3} alt="Open the Business Profile Settings" onImageClick={handleImageClick} />
                </div>
              </div>
            </div>

            {/* Step 4 */}
            <div className="bg-gray-50 dark:bg-gray-800 p-4 md:p-6 rounded-lg">
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center flex-shrink-0">
                  4
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">Go to Business Profile Settings</h3>
                  <p className="text-gray-600 dark:text-gray-300">From the dropdown menu, select <strong>Business Profile settings</strong>.</p>
                  <ImageWithFallback step={4} alt="Go to Business Profile Settings" onImageClick={handleImageClick} />
                </div>
              </div>
            </div>

            {/* Step 5 */}
            <div className="bg-gray-50 dark:bg-gray-800 p-4 md:p-6 rounded-lg">
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center flex-shrink-0">
                  5
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">Access People and Access Settings</h3>
                  <p className="text-gray-600 dark:text-gray-300">In the Business Profile Settings, click <strong>People and access</strong> to manage permissions.</p>
                  <ImageWithFallback step={5} alt="Access People and Access Settings" onImageClick={handleImageClick} />
                </div>
              </div>
            </div>

            {/* Step 6 */}
            <div className="bg-gray-50 dark:bg-gray-800 p-4 md:p-6 rounded-lg">
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center flex-shrink-0">
                  6
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">Add FeedBacked as a Manager</h3>
                  <p className="text-gray-600 dark:text-gray-300">Click the <strong>Add</strong> button and enter our support email:</p>
                  <div className="mt-4 bg-gray-100 rounded-lg p-4 text-center text-gray-500">
                    <code className="text-blue-600">automations@feedbacked.ai</code>
                  </div>
                  <ImageWithFallback step={6} alt="Invite FeedBacked as a Manager" onImageClick={handleImageClick} />
                </div>
              </div>
            </div>

            {/* Step 7 */}
            <div className="bg-gray-50 dark:bg-gray-800 p-4 md:p-6 rounded-lg">
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center flex-shrink-0">
                  7
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">Assign Manager Role</h3>
                  <p className="text-gray-600 dark:text-gray-300">Select <strong>Manager</strong> as the role to gives us the necessary permissions to monitor and respond to your reviews while keeping your ownership secure.</p>
                  <ImageWithFallback step={8} alt="Assign Manager Role" onImageClick={handleImageClick} />
                </div>
              </div>
            </div>

            {/* Step 8 */}
            <div className="bg-gray-50 dark:bg-gray-800 p-4 md:p-6 rounded-lg">
              <div className="flex items-start space-x-4">
                <div className="w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center flex-shrink-0">
                  8
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-3">Send the Invitation</h3>
                  <p className="text-gray-600 dark:text-gray-300">Click <strong>Invite</strong> to send the access request.</p>
                  <ImageWithFallback step={9} alt="Send the Invitation" onImageClick={handleImageClick} />
                </div>
              </div>
            </div>
          </div>

            {/* Footer with adjusted padding */}
            <footer className="mt-6 md:mt-8 pt-4 md:pt-6 border-t border-gray-200">
              <div className="text-center text-sm text-gray-500">
                <p>Need help? Contact us at <a href="mailto:support@feedbacked.ai" className="text-blue-600 hover:text-blue-800">support@feedbacked.ai</a></p>
              </div>
            </footer>
          </div>
        </div>
      </div>

      {/* Add zoomed image overlay */}
      {zoomedImage && (
        <div 
          className="fixed inset-0 z-[60] bg-black bg-opacity-90 flex items-center justify-center p-4"
          onClick={handleZoomClose}
        >
          <div className="relative max-h-[90vh] max-w-[90vw]">
            <img
              src={images[zoomedImage]}
              alt={`Step ${zoomedImage} zoomed`}
              className="max-h-[90vh] max-w-[90vw] object-contain"
            />
            <button
              onClick={handleZoomClose}
              className="absolute -top-4 -right-4 p-2 bg-white rounded-full shadow-md text-gray-400 hover:text-gray-600 transition-colors"
              aria-label="Close zoom view"
            >
              <X size={24} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default GoogleBusinessGuide;
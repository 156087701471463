import { createSlice } from '@reduxjs/toolkit';
import { selectDateFilter } from '../shared/dateFilter/dateFilterSelectors';
import { startOfMonth, format } from 'date-fns';

const initialState = {
  dateFilter: null,
  selectedReportType: null
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
    setSelectedReportType: (state, action) => {
      state.selectedReportType = action.payload;
    },
    resetReportsState: (state) => {
      state.dateFilter = null;
      state.selectedReportType = null;
    }
  }
});

// Selectors
export const selectReportsState = (state) => state.reports;
export const selectSelectedReportType = (state) => state.reports.selectedReportType;

export const selectReportsDateFilter = (state) => {
  const dateFilter = selectDateFilter('reports')(state);
  if (dateFilter?.startDate && dateFilter?.endDate) {
    return dateFilter;
  }
  return {
    startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  };
};

export const { setDateFilter, setSelectedReportType, resetReportsState } = reportsSlice.actions;

export default reportsSlice.reducer;

import { createSlice, createSelector } from '@reduxjs/toolkit';
import { startOfDay, endOfDay, parseISO, isWithinInterval, startOfMonth, format } from 'date-fns';

// Now the slice holds only UI state (if needed)
const initialState = {
  // For example, you could store sort or other local UI states here
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    // Add UI-related reducers if needed
  }
});

// Export selectors that rely on UI state if needed
export const selectMenuState = (state) => state.menu;

// Instead of using a selector to filter data from Redux,
// export a helper function that filters externally provided menu items.
export const filterMenuItems = (menuItems, locationId, dateFilter) => {
  if (!Array.isArray(menuItems)) return [];
  
  let filtered = menuItems;

  if (locationId && locationId !== "all") {
    filtered = filtered.filter(item => item.location_id === locationId);
  }
  
  if (dateFilter?.startDate && dateFilter?.endDate) {
    filtered = filtered.filter((menuItem) => {
      try {
        // Extract just the date part (YYYY-MM-DD) for direct string comparison
        const itemDate = menuItem.review_date.split('T')[0];
        const startDate = dateFilter.startDate.split('T')[0];
        const endDate = dateFilter.endDate.split('T')[0];
        
        // Direct string comparison works for ISO dates (YYYY-MM-DD format)
        return itemDate >= startDate && itemDate <= endDate;
      } catch (error) {
        console.error('Error filtering menu item by date:', error);
        return false;
      }
    });
  }

  return filtered;
};

export const selectMenuDateFilter = createSelector(
  [state => state.dateFilter.menu],
  (dateFilter) => {
    if (dateFilter?.startDate && dateFilter?.endDate) {
      return dateFilter;
    }
    return {
      startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd')
    };
  }
);

export default menuSlice.reducer;

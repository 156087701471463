import { createSelector } from '@reduxjs/toolkit';
import { selectSelectedRestaurant } from '../auth/authSelectors';
import { selectLocations } from '../location/locationSelectors';

// Basic selectors
export const selectAlerts = (state) => state.alerts.items;
export const selectAlertsLoading = (state) => state.alerts.loading;
export const selectAlertsError = (state) => state.alerts.error;

// Enriched Alerts Selector
export const selectEnrichedAlerts = createSelector(
  [
    selectAlerts,
    selectSelectedRestaurant,
  ],
  (alerts, selectedRestaurant) => {
    if (!Array.isArray(alerts)) return [];
    
    // Return the alerts array with minimal transformations
    // Further enrichment will happen in the hook
    return alerts;
  }
);

// Add new selector for alert count
export const selectActiveAlertsCount = createSelector(
  [selectAlerts],
  (alerts) => {
    if (!Array.isArray(alerts)) {
      return 0;
    }
    
    // Count unseen alerts
    return alerts.filter(alert => {
      // Safely check if the alert is not seen
      return alert && alert.seen === false;
    }).length;
  }
);

// You can add more specialized selectors as needed
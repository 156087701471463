import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  selectAuth, 
  selectAuthLoading, 
  selectIsAuthenticated 
} from '../../../store/shared/auth/authSelectors';
import Loading from '../../../components/common/Loading';
import { fetchLocations } from '../../../store/shared/location/locationSlice';
import { 
  selectLocationLoading,
  selectLocationsInitialized,
  selectLocations,
  selectSelectedLocation
} from '../../../store/shared/location/locationSelectors';
import DashboardLayout from './DashboardLayout';
import DashboardRoutes from './DashboardRoutes';
import { getNavigationItems } from '../../../utils/navigationConfig';
import { useLocation } from 'react-router-dom';

const DashboardClient = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  
  // Auth selectors
  const { 
    userRole, 
    selectedRestaurant, 
    accessToken, 
    authInitialized 
  } = useSelector(selectAuth);
  const isAuthLoading = useSelector(selectAuthLoading);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  
  // Location selectors
  const isLocationLoading = useSelector(selectLocationLoading);
  const locationsInitialized = useSelector(selectLocationsInitialized);
  const locations = useSelector(selectLocations);
  const selectedLocation = useSelector(selectSelectedLocation);

  // Get language from URL
  const language = location.pathname.split('/')[1];

  // Fetch locations when component mounts and auth is ready
  useEffect(() => {
    if (isAuthenticated && accessToken && selectedRestaurant?.id && !locationsInitialized) {
      dispatch(fetchLocations());
    }
  }, [dispatch, isAuthenticated, accessToken, locationsInitialized, selectedRestaurant]);

  // Combined loading state
  const isLoading = isAuthLoading || isLocationLoading;

  if (isLoading || !selectedLocation) {
    return <Loading.Spinner fullScreen />;
  }

  return (
    <DashboardLayout
      navigationItems={getNavigationItems(userRole, selectedRestaurant?.tier, language)}
      brandName={selectedRestaurant?.name || "FeedBacked"}
    >
      <DashboardRoutes
        userRole={userRole}
        selectedLocation={selectedLocation}
        locations={locations}
        selectedRestaurant={selectedRestaurant}
      />
    </DashboardLayout>
  );
};

export default DashboardClient;
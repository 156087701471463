// Helper to load JSON translation files from /locales directory
const loadTranslations = async (lang) => {
  try {
    // Updated dynamic imports to reflect the new relative location
    const translations = await import(`../../../locales/${lang}/common.json`);
    const publicTranslations = await import(`../../../locales/${lang}/public.json`);
    const dashboardTranslations = await import(`../../../locales/${lang}/dashboard.json`);

    return {
      common: translations.default,
      public: publicTranslations.default,
      dashboard: dashboardTranslations.default
    };
  } catch (error) {
    console.error(`Failed to load ${lang} translations:`, error);
    // Fallback to English if translations missing
    if (lang !== 'en') {
      return loadTranslations('en');
    }
    return {}; // Prevent complete failure
  }
};

// Service API
export default {
  loadTranslations
};

import { createSlice } from '@reduxjs/toolkit';
import { startOfMonth, format } from 'date-fns';

const initialState = {
  // Store only UI state (e.g., the date filter)
  dateFilter: {
    startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  }
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    setStaffDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
    resetStaffDateFilter: (state) => {
      state.dateFilter = initialState.dateFilter;
    }
  }
});

export const { setStaffDateFilter, resetStaffDateFilter } = staffSlice.actions;
export default staffSlice.reducer;

export const selectStaffDateFilter = (state) => state.staff.dateFilter;

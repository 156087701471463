// src/utils/navigationConfig.js
import { 
    Star,
    BarChart2,
    Users,
    Calendar,
    Settings,
    FileText,
    TrendingUp,
    Utensils,
    Tag,
    House,
    Bell,
    Bot,
    UserCircle,
    MessageSquare,
    Share2,
    Layers
  } from 'lucide-react';
  
  // Define features per tier
  const TIER_FEATURES = {
    Growth: {
      home: true,
      alerts: true,
      reviews: true,
      staff: true,
      menu: true,
      trends: true,
      administration: true
    },
    Essential: {
      home: true,
      alerts: true,
      reviews: true,
      staff: true,
      profiles: true,
      menu: true,
      trends: true,
      keywords: true,
      graphs: true,
      reports: true,
      administration: true
    },
    Pro: {
      home: true,
      agent: true,
      alerts: true,
      reviews: true,
      staff: true,
      profiles: true,
      menu: true,
      socials: true,
      platforms: true,
      trends: true,
      keywords: true,
      graphs: true,
      reports: true,
      administration: true,
      reservations: true,
      integrations: true
    },
    Enterprise: {
      home: true,
      agent: true,
      alerts: true,
      reviews: true,
      staff: true,
      profiles: true,
      menu: true,
      socials: true,
      trends: true,
      keywords: true,
      graphs: true,
      reports: true,
      administration: true,
      reservations: true,
      integrations: true
    }
  };
  
  // Define minimum tier required for each feature
  const FEATURE_MIN_TIER = {
    home: 'Growth',
    alerts: 'Growth',
    agent: 'Pro',
    reviews: 'Growth',
    staff: 'Growth',
    profiles: 'Essential',
    menu: 'Growth',
    socials: 'Pro',
    posts: 'Pro',
    platforms: 'Pro',
    trends: 'Growth',
    keywords: 'Essential',
    graphs: 'Essential',
    reports: 'Essential',
    administration: 'Growth',
    reservations: 'Pro',
    integrations: 'Pro'
  };
  
  // Helper function to check if a feature is available for a tier
  const isFeatureAvailable = (feature, tier) => {
    if (!tier) return false;
    return TIER_FEATURES[tier]?.[feature] || false;
  };
  
  // Helper function to determine if a feature needs a Pro badge
  const needsProBadge = (feature, currentTier) => {
    const minTier = FEATURE_MIN_TIER[feature];
    // Show Pro badge for Pro features to all lower tiers
    if (minTier === 'Pro' && !['Pro', 'Enterprise'].includes(currentTier)) {
      return true;
    }
    // Show Pro badge for Essential features to Growth tier
    if (minTier === 'Essential' && currentTier === 'Growth') {
      return true;
    }
    return false;
  };
  
  export const getNavigationItems = (userRole, subscriptionTier, language) => {
    const prefix = `/${language}`;
    
    const baseItems = [
      {
        icon: House,
        label: 'dashboard.sidebar.nav.home',
        path: `${prefix}/dashboard/home`,
        description: 'dashboard.sidebar.nav.home_description',
        feature: 'home'

      },
      {
        icon: Bot,
        label: 'dashboard.sidebar.nav.agent',
        path: `${prefix}/dashboard/agent`,
        description: 'dashboard.sidebar.nav.agent_description',
        feature: 'agent'
      },

      {
        icon: Bell,
        label: 'dashboard.sidebar.nav.alerts',
        path: `${prefix}/dashboard/alerts`,
        description: 'dashboard.sidebar.nav.alerts_description',
        feature: 'alerts'

      }
    ];
  
    const roleNavigation = {
      SuperAdmin: [
        { icon: MessageSquare, label: 'dashboard.sidebar.nav.reviews', path: `${prefix}/dashboard/reviews`, description: 'dashboard.sidebar.nav.reviews_description', feature: 'reviews' }, 
        { icon: Users, label: 'dashboard.sidebar.nav.staff', path: `${prefix}/dashboard/staff`, description: 'dashboard.sidebar.nav.staff_description', feature: 'staff' },
        { icon: UserCircle, label: 'dashboard.sidebar.nav.profiles', path: `${prefix}/dashboard/staff/profiles`, description: 'dashboard.sidebar.nav.profiles_description', feature: 'profiles' },              
        { icon: Utensils, label: 'dashboard.sidebar.nav.menu', path: `${prefix}/dashboard/menu`, description: 'dashboard.sidebar.nav.menu_description', feature: 'menu' },
        { icon: Share2, label: 'dashboard.sidebar.nav.posts', path: `${prefix}/dashboard/socials/posts`, description: 'dashboard.sidebar.nav.posts_description', feature: 'posts' },
        { icon: Layers, label: 'dashboard.sidebar.nav.platforms', path: `${prefix}/dashboard/socials/platforms`, description: 'dashboard.sidebar.nav.platforms_description', feature: 'platforms' },
        { icon: TrendingUp, label: 'dashboard.sidebar.nav.trends', path: `${prefix}/dashboard/trends`, description: 'dashboard.sidebar.nav.trends_description', feature: 'trends' },
        { icon: Tag, label: 'dashboard.sidebar.nav.keywords', path: `${prefix}/dashboard/keywords`, description: 'dashboard.sidebar.nav.keywords_description', feature: 'keywords' },
        { icon: BarChart2, label: 'dashboard.sidebar.nav.graphs', path: `${prefix}/dashboard/graphs`, description: 'dashboard.sidebar.nav.graphs_description', feature: 'graphs' },
        { icon: FileText, label: 'dashboard.sidebar.nav.reports', path: `${prefix}/dashboard/reports`, description: 'dashboard.sidebar.nav.reports_description', feature: 'reports' },
        { icon: Settings, label: 'dashboard.sidebar.nav.administration', path: `${prefix}/dashboard/administration`, description: 'dashboard.sidebar.nav.administration_description', feature: 'administration' }
      ],

      Admin: [
        { icon: MessageSquare, label: 'dashboard.sidebar.nav.reviews', path: `${prefix}/dashboard/reviews`, description: 'dashboard.sidebar.nav.reviews_description', feature: 'reviews' },
        { icon: Users, label: 'dashboard.sidebar.nav.staff', path: `${prefix}/dashboard/staff`, description: 'dashboard.sidebar.nav.staff_description', feature: 'staff' },
        { icon: UserCircle, label: 'dashboard.sidebar.nav.profiles', path: `${prefix}/dashboard/staff/profiles`, description: 'dashboard.sidebar.nav.profiles_description', feature: 'profiles' },
        { icon: Utensils, label: 'dashboard.sidebar.nav.menu', path: `${prefix}/dashboard/menu`, description: 'dashboard.sidebar.nav.menu_description', feature: 'menu' },
        { icon: Share2, label: 'dashboard.sidebar.nav.posts', path: `${prefix}/dashboard/socials/posts`, description: 'dashboard.sidebar.nav.posts_description', feature: 'posts' },
        { icon: Layers, label: 'dashboard.sidebar.nav.platforms', path: `${prefix}/dashboard/socials/platforms`, description: 'dashboard.sidebar.nav.platforms_description', feature: 'platforms' },
        { icon: TrendingUp, label: 'dashboard.sidebar.nav.trends', path: `${prefix}/dashboard/trends`, description: 'dashboard.sidebar.nav.trends_description', feature: 'trends' },
        { icon: Tag, label: 'dashboard.sidebar.nav.keywords', path: `${prefix}/dashboard/keywords`, description: 'dashboard.sidebar.nav.keywords_description', feature: 'keywords' },
        { icon: BarChart2, label: 'dashboard.sidebar.nav.graphs', path: `${prefix}/dashboard/graphs`, description: 'dashboard.sidebar.nav.graphs_description', feature: 'graphs' },
        { icon: FileText, label: 'dashboard.sidebar.nav.reports', path: `${prefix}/dashboard/reports`, description: 'dashboard.sidebar.nav.reports_description', feature: 'reports' },
        { icon: Settings, label: 'dashboard.sidebar.nav.administration', path: `${prefix}/dashboard/administration`, description: 'dashboard.sidebar.nav.administration_description', feature: 'administration' }
      ],

      Manager: [
        { icon: MessageSquare, label: 'dashboard.sidebar.nav.reviews', path: `${prefix}/dashboard/reviews`, description: 'dashboard.sidebar.nav.reviews_description', feature: 'reviews' },
        { icon: Users, label: 'dashboard.sidebar.nav.staff', path: `${prefix}/dashboard/staff`, description: 'dashboard.sidebar.nav.staff_description', feature: 'staff' },
        { icon: UserCircle, label: 'dashboard.sidebar.nav.profiles', path: `${prefix}/dashboard/staff/profiles`, description: 'dashboard.sidebar.nav.profiles_description', feature: 'profiles' },
        { icon: Share2, label: 'dashboard.sidebar.nav.socials', path: `${prefix}/dashboard/socials`, description: 'dashboard.sidebar.nav.socials_description', feature: 'socials' },
        { icon: BarChart2, label: 'dashboard.sidebar.nav.graphs', path: `${prefix}/dashboard/graphs`, description: 'dashboard.sidebar.nav.graphs_description', feature: 'graphs' }
      ],
      BOH: [
        { icon: Utensils, label: 'dashboard.sidebar.nav.menu', path: `${prefix}/dashboard/menu`, description: 'dashboard.sidebar.nav.menu_description', feature: 'menu' },
        { icon: FileText, label: 'dashboard.sidebar.nav.reports', path: `${prefix}/dashboard/reports`, description: 'dashboard.sidebar.nav.reports_description', feature: 'reports' }
      ]

    };
  
    // Integration section - only for Pro and Enterprise tier
    const integrationItems = [
      { 
        icon: Calendar, 
        label: 'Reservations', 
        path: `${prefix}/dashboard/reservations`, 
        description: 'Booking Management',
        feature: 'reservations'
      }
    ];
  
    // Get all items for the role
    let items = [];
    if (userRole === 'SuperAdmin') {
      items = [
        ...baseItems,
        ...roleNavigation.SuperAdmin,
        ...(subscriptionTier === 'Pro' || subscriptionTier === 'Enterprise' ? integrationItems : [])
      ];
    } else {
      items = [
        ...baseItems,
        ...(roleNavigation[userRole] || []),
        ...((['Pro', 'Enterprise'].includes(subscriptionTier)) ? integrationItems : [])
      ];
    }
  
    // Add needsProBadge property to each item
    return items.map(item => ({
      ...item,
      path: item.path,
      needsProBadge: needsProBadge(item.feature, subscriptionTier)
    }));
  };
  
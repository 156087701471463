import { apiRequest, getStaffMentionProfiles } from "../../../services/api/apiUtility";
import { API_ENDPOINTS } from "../../../services/api/config";

export const getStaffProfiles = async (token, { dateFilter, limit = 10, offset = 0, locationId = 'all' }) => {
  try {
    // Ensure we're passing the proper date format
    const dateParams = dateFilter?.startDate && dateFilter?.endDate
      ? { 
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate
        }
      : null;
      
    return await getStaffMentionProfiles(
      locationId,
      token,
      { dateFilter: dateParams, limit, offset }
    );
  } catch (error) {
    console.error('Error fetching staff profiles:', error);
    throw error;
  }
};

// User Details Selectors
export const selectUserUsername = (state) => state.auth.userUsername;
export const selectUserEmail = (state) => state.auth.userEmail;
export const selectUserFirstName = (state) => state.auth.userFirstName;
export const selectUserLastName = (state) => state.auth.userLastName;
export const selectUserRole = (state) => state.auth.userRole;
export const selectUserId = (state) => state.auth.userId;
export const selectUserLocationId = (state) => state.auth.userLocationId;
export const selectUserEnabled = (state) => state.auth.userEnabled;
export const selectUserRoleId = (state) => state.auth.userRoleId;
export const selectSubscriptionTier = (state) => state.auth.subscriptionTier;

// Extra Selectors
export const selectAuth = (state) => state.auth;
export const selectIsAuthenticated = (state) => Boolean(state.auth.accessToken && state.auth.authInitialized);
export const selectAuthorizedLocations = (state) => state.auth.authorizedLocations;
export const selectSelectedRestaurant = (state) => state.auth.selectedRestaurant;
export const selectAuthLoading = (state) => state.auth.isLoading;
export const selectAuthError = (state) => state.auth.error;
export const selectAccessToken = (state) => state.auth.accessToken;
export const selectAccessTokenExpiresIn = (state) => state.auth.accessTokenExpiresIn;
export const selectAuthInitialized = (state) => state.auth.authInitialized;
export const selectRefreshTokenTimeoutId = (state) => state.auth.refreshTokenTimeoutId;
export const selectLastRefreshTime = (state) => state.auth.lastRefreshTime;
import { useDispatch, useSelector } from 'react-redux';
import { submitOnboarding, resetOnboardingState } from '../../../../store/slices/onboardingSlice';

export const useOnboarding = () => {
  const dispatch = useDispatch();
  const { isSubmitting, submitSuccess, error } = useSelector((state) => state.onboarding);

  const handleSubmitOnboarding = async (formData) => {
    try {
      await dispatch(submitOnboarding(formData)).unwrap();
      return true;
    } catch (error) {
      return false;
    }
  };

  const resetState = () => {
    dispatch(resetOnboardingState());
  };

  return {
    isSubmitting,
    submitSuccess,
    error,
    handleSubmitOnboarding,
    resetState,
  };
}; 
// src/App.js
import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { 
  selectIsAuthenticated, 
  selectAuthInitialized,
  selectUserRole,
  selectSelectedRestaurant 
} from './store/shared/auth/authSelectors';
import Loading from "./components/common/Loading";
import Login from "./features/auth/components/Login";
import DashboardClient from "./features/dashboard/components/DashboardClient";
import LandingPage from "./features/public/components/LandingPage";
import Blog from "./features/public/components/Blog";
import Enterprise from "./features/public/components/Enterprise";
import Pricing from "./features/public/components/Pricing";
import Solutions from "./features/public/components/Solutions";
import { ThemeProvider } from "./contexts/ThemeContext";
import "./assets/styles/index.css";
import "./assets/styles/App.css";
import OnboardingFlow from "./features/public/onboarding/components/OnboardingFlow";
import { refreshAuth } from './store/shared/auth/authThunks';
import { setAuthInitialized } from './store/shared/auth/authSlice';
import PublicLayout from "./components/layout/PublicLayout";
import EnterpriseQuote from "./features/public/components/EnterpriseQuote";
import SuperAdmin from "./features/superadmin/components/SuperAdmin";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import LanguageRouter from './features/language/components/LanguageRouter';

// Protected Route Component (updated)
const ProtectedRoute = ({ children, allowedRoles = [], requireRestaurant = false }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userRole = useSelector(selectUserRole);
  const selectedRestaurant = useSelector(selectSelectedRestaurant);

  // If the user is not authenticated, redirect to the login page.
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Check role restrictions if provided.
  if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
    return <Navigate to="/dashboard" replace />;
  }

  // If restaurant is required and user is not SuperAdmin (who bypasses this) but none is selected,
  // redirect to login.
  if (requireRestaurant && !selectedRestaurant && userRole !== "SuperAdmin") {
    const prefix = `/${window.location.pathname.split('/')[1]}`;
    return <Navigate to={`${prefix}/login`} replace />;
  }

  // If all checks pass, render the children.
  return children;
};

// Public Route Component
const PublicRoute = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userRole = useSelector(selectUserRole);
  
  if (isAuthenticated) {
    // Preserve language prefix in redirects

        // Redirect based on role
        if (userRole === "SuperAdmin") {
          return <Navigate to="/superadmin" replace />;
        }
        
    const prefix = `/${window.location.pathname.split('/')[1]}`;
    return <Navigate to={`${prefix}/dashboard/home`} replace />;
  }
  
  return children;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const authInitialized = useSelector(selectAuthInitialized);

  // Try to initialize auth on app load
  useEffect(() => {
    if (!authInitialized) {
      dispatch(refreshAuth())
        .finally(() => {
          // Ensure authInitialized is set even if refresh fails
          dispatch(setAuthInitialized(true));
        });
    }
  }, [dispatch, authInitialized]);

  // if (!authInitialized) {
  //   return <Loading.Spinner fullScreen />;
  // }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Router>
          <LanguageRouter>
            <Routes>
              {/* Inserted new login route above the PublicLayout route */}
              <Route path="/:lang/login" element={<PublicRoute><Login /></PublicRoute>} />

              {/* Nested Public Routes */}
              <Route path="/:lang" element={<PublicRoute><PublicLayout /></PublicRoute>}>
                <Route index element={<LandingPage />} />
                <Route path="blog" element={<Blog />} />
                <Route path="enterprise" element={<Enterprise />} />
                <Route path="solutions" element={<Solutions />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="onboarding" element={<OnboardingFlow />} />
                <Route path="enterprise-quote" element={<EnterpriseQuote />} />
                
                {/* Add catch-all for public routes */}
                <Route path="*" element={<Navigate to="/en" replace />} />
              </Route>

              {/* Protected Routes */}
              <Route path="/:lang">
                <Route path="dashboard/*" element={
                  <ProtectedRoute requireRestaurant>
                    <Routes>
                      <Route path="/*" element={<DashboardClient />} />
                    </Routes>
                  </ProtectedRoute>
                }/>
                <Route path="superadmin/*" element={
                  <ProtectedRoute allowedRoles={["SuperAdmin"]}>
                    <SuperAdmin />
                  </ProtectedRoute>
                }/>
              </Route>

              {/* Catch-all Route */}
              <Route path="*" element={<Navigate to="/en" replace />} />
            </Routes>
          </LanguageRouter>
        </Router>
      </ThemeProvider>
      <ReactQueryDevtools /> {/* Development only */}
    </QueryClientProvider>
  );
}

export default App;
import React from "react";
import {
  MessageSquare,
  Users,
  Star,
  BarChart4,
  Search,
  Settings2,
  Zap,
  Clock,
  ArrowRight,
  Play,
} from "lucide-react";
import { useLanguage } from "../../language/hooks/useLanguageHook";

const SolutionCard = ({ icon: Icon, title, description, features }) => (
  <div className="p-8 bg-white rounded-xl shadow-sm hover:shadow-xl transition-all duration-300 border border-gray-100">
    <div className="bg-blue-100 w-16 h-16 rounded-xl flex items-center justify-center mb-6 transform group-hover:rotate-12 transition-transform">
      <Icon className="h-8 w-8 text-blue-600" />
    </div>
    <h3 className="text-xl font-semibold mb-4">{title}</h3>
    <p className="text-gray-600 mb-6">{description}</p>
    <ul className="space-y-3">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start group">
          <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center mr-3 mt-0.5 group-hover:bg-blue-600 transition-colors">
            <Zap className="h-3.5 w-3.5 text-blue-600 group-hover:text-white transition-colors" />
          </div>
          <span className="text-gray-700">{feature}</span>
        </li>
      ))}
    </ul>
  </div>
);

const ProcessStep = ({ number, title, description }) => (
  <div className="relative">
    <div className="flex items-center mb-4">
      <div className="w-12 h-12 rounded-full bg-blue-600 text-white flex items-center justify-center text-xl font-bold">
        {number}
      </div>
      <div className="ml-4">
        <h4 className="text-lg font-semibold">{title}</h4>
      </div>
    </div>
    <p className="text-gray-600 ml-16">{description}</p>
  </div>
);

const Solutions = () => {
  const { t } = useLanguage();

  const solutions = [
    {
      icon: MessageSquare,
      title: t("public.solutions.cards.smartReview.title"),
      description: t("public.solutions.cards.smartReview.description"),
      features: [
        t("public.solutions.cards.smartReview.features.templates"),
        t("public.solutions.cards.smartReview.features.sentiment"),
        t("public.solutions.cards.smartReview.features.language"),
        t("public.solutions.cards.smartReview.features.brand"),
      ],
    },
    {
      icon: Users,
      title: t("public.solutions.cards.performance.title"),
      description: t("public.solutions.cards.performance.description"),
      features: [
        t("public.solutions.cards.performance.features.tracking"),
        t("public.solutions.cards.performance.features.analytics"),
        t("public.solutions.cards.performance.features.recognition"),
        t("public.solutions.cards.performance.features.training"),
      ],
    },
    {
      icon: BarChart4,
      title: t("public.solutions.cards.analytics.title"),
      description: t("public.solutions.cards.analytics.description"),
      features: [
        t("public.solutions.cards.analytics.features.dashboards"),
        t("public.solutions.cards.analytics.features.trends"),
        t("public.solutions.cards.analytics.features.benchmarking"),
        t("public.solutions.cards.analytics.features.export"),
      ],
    },
    {
      icon: Search,
      title: t("public.solutions.cards.monitoring.title"),
      description: t("public.solutions.cards.monitoring.description"),
      features: [
        t("public.solutions.cards.monitoring.features.realtime"),
        t("public.solutions.cards.monitoring.features.integration"),
        t("public.solutions.cards.monitoring.features.alerts"),
        t("public.solutions.cards.monitoring.features.consolidation"),
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Enhanced Hero Section */}
      <div className="relative overflow-hidden bg-white">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-transparent" />
          <div className="absolute inset-0 bg-[radial-gradient(45%_45%_at_50%_50%,rgba(37,99,235,0.1)_0%,rgba(255,255,255,0)_100%)]" />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
          <div className="text-center">
            <div className="inline-flex items-center px-4 py-2 rounded-full bg-blue-100 text-blue-700 mb-8">
              <Star className="h-4 w-4 mr-2" fill="currentColor" />
              <span className="text-sm font-medium">
                {t("public.solutions.hero.trusted")}
              </span>
            </div>
            <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold text-gray-900 mb-8 leading-tight">
              {t("public.solutions.hero.title1")}
              <br />
              <span className="text-blue-600 relative">
                {t("public.solutions.hero.title2")}
                <svg
                  className="absolute -bottom-2 left-0 w-full"
                  height="8"
                  viewBox="0 0 200 8"
                >
                  <path
                    d="M0 4C50 4 50 1 100 1S150 7 200 7"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              </span>
            </h1>
            <p className="max-w-3xl mx-auto text-xl text-gray-600 mb-12">
              {t("public.solutions.hero.subtitle")}
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <button className="bg-blue-600 text-white px-8 py-4 rounded-lg hover:bg-blue-700 transition-all transform hover:scale-105 hover:shadow-lg flex items-center justify-center">
                {t("public.solutions.cta.startTrial")}
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button className="bg-white text-gray-900 px-8 py-4 rounded-lg border-2 border-gray-200 hover:border-blue-600 transition-all flex items-center justify-center group">
                <Play className="mr-2 h-5 w-5 text-blue-600 group-hover:scale-110 transition-transform" />
                {t("public.solutions.cta.watchDemo")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Process Section */}
      <div className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              {t("public.solutions.process.title")}
            </h2>
            <p className="text-xl text-gray-600">
              {t("public.solutions.process.subtitle")}
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-12">
            <ProcessStep
              number="1"
              title={t("public.solutions.process.step1.title")}
              description={t("public.solutions.process.step1.description")}
            />
            <ProcessStep
              number="2"
              title={t("public.solutions.process.step2.title")}
              description={t("public.solutions.process.step2.description")}
            />
            <ProcessStep
              number="3"
              title={t("public.solutions.process.step3.title")}
              description={t("public.solutions.process.step3.description")}
            />
          </div>
        </div>
      </div>

      {/* Enhanced Solutions Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            {t("public.solutions.grid.title")}
          </h2>
          <p className="text-xl text-gray-600">
            {t("public.solutions.grid.subtitle")}
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8">
          {solutions.map((solution) => (
            <SolutionCard key={solution.title} {...solution} />
          ))}
        </div>
      </div>

      {/* Enhanced Features Overview */}
      <div className="bg-gradient-to-b from-white to-gray-50 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              {t("public.solutions.features.title")}
            </h2>
            <p className="text-xl text-gray-600">
              {t("public.solutions.features.subtitle")}
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: Settings2,
                title: t("public.solutions.features.integration.title"),
                description: t("public.solutions.features.integration.description"),
              },
              {
                icon: Clock,
                title: t("public.solutions.features.timeSaving.title"),
                description: t("public.solutions.features.timeSaving.description"),
              },
              {
                icon: Star,
                title: t("public.solutions.features.ratings.title"),
                description: t("public.solutions.features.ratings.description"),
              },
            ].map((feature) => (
              <div
                key={feature.title}
                className="bg-white rounded-xl p-8 text-center transform hover:-translate-y-1 transition-all duration-300 shadow-sm hover:shadow-xl"
              >
                <div className="bg-blue-100 w-16 h-16 rounded-xl flex items-center justify-center mx-auto mb-6">
                  <feature.icon className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-lg font-semibold mb-4">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Enhanced CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-4xl font-bold mb-8">
            {t("public.solutions.finalCta.title")}
          </h2>
          <p className="text-xl mb-12 text-blue-100 max-w-3xl mx-auto">
            {t("public.solutions.finalCta.subtitle")}
          </p>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <button className="bg-white text-blue-600 px-8 py-4 rounded-lg hover:bg-blue-50 transition-all transform hover:scale-105 hover:shadow-lg">
              {t("public.solutions.finalCta.buttons.trial")}
            </button>
            <button className="border-2 border-white text-white px-8 py-4 rounded-lg hover:bg-white/10 transition-all">
              {t("public.solutions.finalCta.buttons.demo")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;

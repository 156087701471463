import { 
  format, 
  parse,
  endOfDay,
  subDays, 
  isWithinInterval,
  differenceInDays,
  parseISO,
  addDays,
  startOfDay,
} from 'date-fns';
import { SECTIONS, DATE_CONFIGURATIONS } from '../config/dateConfig';
import { isDateInRange as isDateInRangeUtil } from '../../../utils/dateUtils';

// Constants
export const DATE_FORMATS = {
    API: 'yyyy-MM-dd',
    DISPLAY: 'MMM dd, yyyy',
    MONTH_YEAR: 'MMM yyyy',
    WEEKDAY: 'EEE'
};

// ============= Basic Utilities =============
export const formatDate = (date) => format(date, DATE_FORMATS.API);
export const parseDate = (dateString) => parse(dateString, DATE_FORMATS.API, new Date());
export const parseDateSafe = (dateString, format = DATE_FORMATS.API) => {
  try {
    return parse(dateString, format, new Date());
  } catch {
    return null;
  }
};

export const getCurrentDate = () => {
  // Create a date and strip the time component by formatting as string and parsing back
  const now = new Date();
  const dateString = format(now, DATE_FORMATS.API);
  return parse(dateString, DATE_FORMATS.API, new Date());
};

// ============= Range Utilities =============
export const createDateRange = (startDate, endDate) => {
  // Ensure dates are parsed and formatted consistently
  let start, end;
  
  try {
    start = typeof startDate === 'string' ? parseISO(startDate) : startDate;
    end = typeof endDate === 'string' ? parseISO(endDate) : endDate;
  } catch (error) {
    console.error('Error parsing dates:', error);
    start = new Date();
    end = new Date();
  }
  
  // Format to yyyy-MM-dd to remove time component
  return {
    startDate: format(start, DATE_FORMATS.API),
    endDate: format(end, DATE_FORMATS.API)
  };
};

// Date range validation
export const isValidDateRange = ({ startDate, endDate }) => {
  try {
    const start = parseISO(startDate);
    const end = parseISO(endDate);
    return start <= end;
  } catch (error) {
    return false;
  }
};

// Validate custom date ranges including comparison
export const validateCustomDateRange = (current, comparison = null) => {
  if (!isValidDateRange(current)) return false;
  
  if (comparison) {
    if (!isValidDateRange(comparison)) return false;
    const currentStart = parseISO(current.startDate);
    const comparisonEnd = parseISO(comparison.endDate);
    return comparisonEnd < currentStart;
  }
  
  return true;
};

// Check if a date falls within a range
export const isDateInRange = (date, { startDate, endDate }) => {
  return isDateInRangeUtil(date, { startDate, endDate });
};

// Calculate date range duration
export const getDateRangeDuration = ({ startDate, endDate }) => {
  try {
    return differenceInDays(parseISO(endDate), parseISO(startDate)) + 1;
  } catch (error) {
    return 0;
  }
};

// Generate comparison date range
export const getComparisonDateRange = ({ startDate, endDate }) => {
  try {
    const start = parseISO(startDate);
    const end = parseISO(endDate);
    const duration = differenceInDays(end, start);
    
    return {
      startDate: formatDate(subDays(start, duration + 1)),
      endDate: formatDate(subDays(start, 1))
    };
  } catch (error) {
    return null;
  }
};

// Get default date range for a section
export const getDefaultDateRange = (section) => {
  const sectionConfig = SECTIONS[section];
  if (!sectionConfig) {
    console.warn(`No configuration found for section: ${section}`);
    return null;
  }
  
  const preset = DATE_CONFIGURATIONS[sectionConfig.defaultPreset];
  if (!preset) {
    console.warn(`No preset found for section: ${section}`);
    return null;
  }

  return preset.getDates(getCurrentDate());
};

// ============= Calculations =============
export const calculatePercentageChange = (current, previous) => {
  if (previous === 0 && current === 0) return 0;
  if (previous === 0) return current > 0 ? 100 : -100;
  return ((current - previous) / Math.abs(previous)) * 100;
};

// ============= Data Processing =============
export const filterItemsByDateRange = (items, dateRange, dateField = 'date') => {
  if (!items?.length || !dateRange) return [];
  
  return items.filter(item => {
    const itemDate = item[dateField];
    if (!itemDate) return false;
    
    const isIn = isDateInRange(itemDate, dateRange);
    return isIn;
  });
};

// Group items by date within range
export const groupItemsByDate = (items, dateField = 'date') => {
  if (!items?.length) return {};
  
  return items.reduce((acc, item) => {
    const date = formatDate(parseISO(item[dateField]));
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});
};

// Fill missing dates in a range with default values
export const fillMissingDates = (dateRange, data, defaultValue = 0) => {
  const { startDate, endDate } = dateRange;
  const start = parseISO(startDate);
  const end = parseISO(endDate);
  const result = {};
  
  let current = start;
  while (current <= end) {
    const dateStr = formatDate(current);
    result[dateStr] = data[dateStr] || defaultValue;
    current = addDays(current, 1);
  }
  
  return result;
};

// Normalize date range input with validation
export const normalizeDateRange = (dateRange, section) => {
  if (!dateRange?.startDate || !dateRange?.endDate) {
    return getDefaultDateRange(section);
  }

  try {
    const start = startOfDay(parseISO(dateRange.startDate));
    const end = startOfDay(parseISO(dateRange.endDate));
    
    if (end < start) {
      return getDefaultDateRange(section);
    }

    return {
      startDate: formatDate(start),
      endDate: formatDate(end)
    };
  } catch (error) {
    console.error('Invalid date range format:', error);
    return getDefaultDateRange(section);
  }
};

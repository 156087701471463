import { createSlice } from "@reduxjs/toolkit";
import { startOfMonth, format } from "date-fns";

const initialState = {
  // Keep only UI state
  dateFilter: {
    startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd')
  },
  filters: {
    selectedRating: null,
    selectedFlag: null,
    selectedStatus: null,
    searchTerm: '',
  },
  modalState: {
    isOpen: false,
    selectedReviewId: null,
  },
  userPreferences: {
    defaultView: 'list',
    sortOrder: 'desc',
    sortBy: 'date',
  }
};

const reviewSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    setDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setModalState: (state, action) => {
      state.modalState = { ...state.modalState, ...action.payload };
    },
    setUserPreferences: (state, action) => {
      state.userPreferences = { ...state.userPreferences, ...action.payload };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  }
});

// Selectors
export const selectReviewsDateFilter = (state) => state.reviews.dateFilter;
export const selectReviewsFilters = (state) => state.reviews.filters;
export const selectReviewsModalState = (state) => state.reviews.modalState;
export const selectReviewsUserPreferences = (state) => state.reviews.userPreferences;

export const { 
  setDateFilter, 
  setFilters, 
  setModalState, 
  setUserPreferences, 
  resetFilters 
} = reviewSlice.actions;

export default reviewSlice.reducer;

import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../../../components/common/Loading';
import { useSelector } from 'react-redux';
import { selectSubscriptionTier } from '../../../store/shared/auth/authSelectors';


// Lazy load route components
const Home = React.lazy(() => import('../../home/components/Home'));
const Alerts = React.lazy(() => import('../../alerts/components/Alerts'));
const Agent = React.lazy(() => import('../../agent/components/Agent'));
const Trends = React.lazy(() => import('../../trend/components/Trends'));
const Reviews = React.lazy(() => import('../../reviews/components/Reviews'));
const Staff = React.lazy(() => import('../../staff/components/Staff'));
const Menu = React.lazy(() => import('../../menu/components/Menu'));
const Keywords = React.lazy(() => import('../../keywords/components/Keywords'));
const Administration = React.lazy(() => import('../../administration/component/Administration'));
const Analytics = React.lazy(() => import('../../graphs/components/DashboardCharts.js'));
const Reports = React.lazy(() => import('../../reports/components/Reports'));
const Profile = React.lazy(() => import('../../profile/components/Profile'));
const Reservations = React.lazy(() => import('../../reservations/components/Reservations'));
const Platforms = React.lazy(() => import('../../socials/platforms/components/Platforms'));
const Posts = React.lazy(() => import('../../socials/posts/components/Posts.js'));

// Define tier requirements for features
const TIER_REQUIREMENTS = {
  // Base features (available to all tiers)
  home: ['Growth', 'Essential', 'Pro', 'Enterprise'],
  alerts: ['Growth', 'Essential', 'Pro', 'Enterprise'],
  reviews: ['Growth', 'Essential', 'Pro', 'Enterprise'],
  staff: ['Growth', 'Essential', 'Pro', 'Enterprise'],
  menu: ['Growth', 'Essential', 'Pro', 'Enterprise'],
  trends: ['Growth', 'Essential', 'Pro', 'Enterprise'],
  administration: ['Growth', 'Essential', 'Pro', 'Enterprise'],
  
  // Essential+ features
  profiles: ['Essential', 'Pro', 'Enterprise'],
  keywords: ['Essential', 'Pro', 'Enterprise'],
  graphs: ['Essential', 'Pro', 'Enterprise'],
  reports: ['Essential', 'Pro', 'Enterprise'],
  
  // Pro/Enterprise only features
  agent: ['Pro', 'Enterprise'],
  socials: ['Pro', 'Enterprise'],
  platforms: ['Pro', 'Enterprise'],
  reservations: ['Pro', 'Enterprise'],
  integrations: ['Pro', 'Enterprise'],
  posts: ['Pro', 'Enterprise'],
};

// Helper component to check both role and tier access
const ProtectedRoute = ({ element, feature, allowedRoles, userRole }) => {
  const subscriptionTier = useSelector(selectSubscriptionTier);
  const hasRoleAccess = allowedRoles.includes("SuperAdmin") || allowedRoles.includes(userRole);
  const hasTierAccess = TIER_REQUIREMENTS[feature]?.includes(subscriptionTier);

  // SuperAdmin bypasses tier restrictions
  if (userRole === "SuperAdmin") return element;
  
  // Check both role and tier access
  if (hasRoleAccess && hasTierAccess) {
    return element;
  }
  
  // Redirect to home if access denied
  return <Navigate to="home" replace />;
};

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  feature: PropTypes.string.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  userRole: PropTypes.string.isRequired
};

const DashboardRoutes = ({
  userRole,
  selectedLocation,
}) => {
  return (
    <Suspense fallback={<Loading.ContentLoader />}>
      <Routes>
        {/* Base routes available to all roles based on tier */}
        <Route 
          path="home"
          element={
            <ProtectedRoute
              element={<Home selectedLocation={selectedLocation} />}
              feature="home"
              allowedRoles={["SuperAdmin", "Admin", "Manager", "BOH"]}
              userRole={userRole}
            />
          }
        />
        <Route 
          path="alerts"
          element={
            <ProtectedRoute
              element={<Alerts selectedLocation={selectedLocation} />}
              feature="alerts"
              allowedRoles={["SuperAdmin", "Admin", "Manager", "BOH"]}
              userRole={userRole}
            />
          }
        />

        {/* Admin and SuperAdmin routes */}
        <Route 
          path="agent" 
          element={
            <ProtectedRoute
              element={<Agent selectedLocation={selectedLocation} />}
              feature="agent"
              allowedRoles={["SuperAdmin", "Admin"]}
              userRole={userRole}
            />
          }
        />

        {/* Routes for SuperAdmin, Admin, and Manager */}
        <Route 
          path="trends" 
          element={
            <ProtectedRoute
              element={<Trends selectedLocation={selectedLocation} />}
              feature="trends"
              allowedRoles={["SuperAdmin", "Admin", "Manager"]}
              userRole={userRole}
            />
          }
        />
        <Route 
          path="reviews" 
          element={
            <ProtectedRoute
              element={<Reviews selectedLocation={selectedLocation} />}
              feature="reviews"
              allowedRoles={["SuperAdmin", "Admin", "Manager"]}
              userRole={userRole}
            />
          }
        />
        <Route 
          path="staff" 
          element={
            <ProtectedRoute
              element={<Staff selectedLocation={selectedLocation} />}
              feature="staff"
              allowedRoles={["SuperAdmin", "Admin", "Manager"]}
              userRole={userRole}
            />
          }
        />
        <Route 
          path="staff/profiles" 
          element={
            <ProtectedRoute
              element={<Profile selectedLocation={selectedLocation} />}
              feature="profiles"
              allowedRoles={["SuperAdmin", "Admin", "Manager"]}
              userRole={userRole}
            />
          }
        />
        <Route 
          path="graphs" 
          element={
            <ProtectedRoute
              element={<Analytics selectedLocation={selectedLocation} />}
              feature="graphs"
              allowedRoles={["SuperAdmin", "Admin", "Manager"]}
              userRole={userRole}
            />
          }
        />
        <Route 
          path="reports" 
          element={
            <ProtectedRoute
              element={<Reports selectedLocation={selectedLocation} />}
              feature="reports"
              allowedRoles={["SuperAdmin", "Admin", "Manager", "BOH"]}
              userRole={userRole}
            />
          }
        />

        {/* Admin and SuperAdmin specific routes */}
        <Route 
          path="keywords" 
          element={
            <ProtectedRoute
              element={<Keywords selectedLocation={selectedLocation} />}
              feature="keywords"
              allowedRoles={["SuperAdmin", "Admin"]}
              userRole={userRole}
            />
          }
        />
        <Route 
          path="administration" 
          element={
            <ProtectedRoute
              element={<Administration selectedLocation={selectedLocation} />}
              feature="administration"
              allowedRoles={["SuperAdmin", "Admin"]}
              userRole={userRole}
            />
          }
        />

        {/* Menu access for SuperAdmin, Admin, and BOH */}
        <Route 
          path="menu" 
          element={
            <ProtectedRoute
              element={<Menu selectedLocation={selectedLocation} />}
              feature="menu"
              allowedRoles={["SuperAdmin", "Admin", "BOH"]}
              userRole={userRole}
            />
          }
        />

        {/* Pro tier only routes */}
        <Route 
          path="reservations" 
          element={
            <ProtectedRoute
              element={<Reservations selectedLocation={selectedLocation} />}
              feature="reservations"
              allowedRoles={["SuperAdmin", "Admin"]}
              userRole={userRole}
            />
          }
        />
        
        {/* Posts route - nested under socials */}
        <Route 
          path="socials/posts"
          element={
            <ProtectedRoute
              element={<Posts />}
              feature="posts"
              allowedRoles={["SuperAdmin", "Admin"]}
              userRole={userRole}
            />
          }
        />

        {/* Platforms route - nested under socials */}
        <Route 
          path="socials/platforms"
          element={
            <ProtectedRoute
              element={<Platforms />}
              feature="platforms"
              allowedRoles={["SuperAdmin", "Admin"]}
              userRole={userRole}
            />
          }
        />

        {/* Default redirect */}
        <Route path="*" element={<Navigate to="home" replace />} />
      </Routes>
    </Suspense>
  );
};

DashboardRoutes.propTypes = {
  userRole: PropTypes.string.isRequired,
  selectedLocation: PropTypes.object,
  locations: PropTypes.array,
  selectedRestaurant: PropTypes.object
};

export default DashboardRoutes;
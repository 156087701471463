import { apiRequest } from '../../../services/api/apiUtility';
import { API_ENDPOINTS } from '../../../services/api/config';
import { parseISO, format } from 'date-fns';

export const getGoogleReviews = async (locationId, offset = 0, limit = 50, token, dateFilter = null) => {
  try {
    // Build base URL
    let url = API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.PAGINATED(locationId, offset, limit);
    
    // Add date parameters only if dateFilter is provided and has both dates
    if (dateFilter?.startDate && dateFilter?.endDate) {
      const startDate = format(parseISO(dateFilter.startDate), 'yyyy-MM-dd');
      const endDate = format(parseISO(dateFilter.endDate), 'yyyy-MM-dd');

      url += `?startDate=${startDate}&endDate=${endDate}`;
    }

    return await apiRequest(
      url,
      'GET',
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error fetching Google reviews:', error);
    throw error;
  }
};

export const getRegularReviews = async (locationId, offset = 0, limit = 50, token, dateFilter = null) => {
  try {
    // Build base URL
    let url = API_ENDPOINTS.LOCATION.REVIEWS.PAGINATED(locationId, offset, limit);
    
    // Add date parameters if provided
    if (dateFilter?.startDate && dateFilter?.endDate) {
      const startDate = format(parseISO(dateFilter.startDate), 'yyyy-MM-dd');
      const endDate = format(parseISO(dateFilter.endDate), 'yyyy-MM-dd');

      url += `?startDate=${startDate}&endDate=${endDate}`;
    }

    return await apiRequest(
      url,
      'GET',
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error('Error fetching regular reviews:', error);
    throw error;
  }
};

export const updateReviewResponse = async (
  locationId,
  reviewId,
  response,
  token
) => {
  try {
    const result = await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.UPDATE_RESPONSE(locationId, reviewId),
      'PUT',
      { response },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return result;
  } catch (error) {
    console.error('Error updating review response:', error);
    throw error;
  }
};

export const regenerateReviewResponseGPT = async (locationId, reviewId, token, additionalInstructions = "") => {
  try {
    const result = await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.REGENERATE_RESPONSE(locationId, reviewId),
      'POST',
      { additional_instructions: additionalInstructions },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return result;
  } catch (error) {
    console.error('Error regenerating review response:', error);
    throw error;
  }
};

export const postReviewResponse = async (locationId, reviewId, accessToken) => {
  try {
    return await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.POST_RESPONSE(locationId, reviewId),
      'PUT',
      null,
      {},
      { Authorization: `Bearer ${accessToken}` }
    );
  } catch (error) {
    console.error('Error posting review response:', error);
    throw error;
  }
};

export const getReviewById = async (locationId, reviewId, token) => {
  if (!locationId || !reviewId || !token) {
    throw new Error('Missing required parameters for getReviewById');
  }

  try {
    // First try to get the review from the regular reviews endpoint
    try {
      const response = await apiRequest(
        API_ENDPOINTS.LOCATION.REVIEWS.GET_BY_ID(locationId, reviewId),
        'GET',
        null,
        {},
        { Authorization: `Bearer ${token}` }
      );
      
      if (response?.data) {
        return response;
      }
      // If no data but no error, continue to Google reviews
    } catch (regularError) {
      // Only log if not a 404 error (which is expected if review is a Google review)
      if (regularError.status !== 404) {
        console.error('Error fetching review by ID from regular endpoint:', regularError);
      }
    }
    
    // Try Google reviews as fallback
    try {
      const response = await apiRequest(
        API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.BY_ID(locationId, reviewId),
        'GET',
        null,
        {},
        { Authorization: `Bearer ${token}` }
      );
      
      if (response?.data) {
        return response;
      }
      
      throw new Error(`Review ${reviewId} not found in location ${locationId}`);
    } catch (googleError) {
      console.error('Error fetching review by ID from Google endpoint:', googleError);
      throw googleError;
    }
  } catch (error) {
    console.error(`Error fetching review ${reviewId} from location ${locationId}:`, error);
    throw error;
  }
};


import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18nService from '../services/i18nServices'; // Service to load translation JSON files
import { setLanguage } from '../../../store/slices/languageSlice'; // Action from your language slice

export const useLanguage = () => {
  const dispatch = useDispatch();
  // Grab the current language and translations (if any) from Redux state
  const { language, translations } = useSelector((state) => state.language);

  // Function to load translations via our i18n service and update Redux state
  const loadTranslations = useCallback(async (lang) => {
    try {
      const data = await i18nService.loadTranslations(lang);
      dispatch(setLanguage({ language: lang, translations: data }));
    } catch (error) {
      console.error('Error loading translations:', error);
    }
  }, [dispatch]);

  // When the hook loads, if translations do not exist, load them for the current language
  useEffect(() => {
    if (!translations) {
      loadTranslations(language);
    }
  }, [language, translations, loadTranslations]);

  // Simple t() function for translating keys (supports nested keys like "common.title")
  const t = useCallback((key) => {
    if (!translations) return key; // fallback: return the key if not loaded
    const keys = key.split('.');
    let value = translations;
    for (const k of keys) {
      value = value[k];
      if (value === undefined) {
        return key; // fallback if key is missing
      }
    }
    return value;
  }, [translations]);

  // Function to change language on demand
  const changeLanguage = useCallback((lang) => {
    loadTranslations(lang);
  }, [loadTranslations]);

  return { language, t, changeLanguage, translations };
};

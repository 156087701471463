import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserAlerts, markAlertAsSeen, markManyAlertsAsSeen } from '../../../services/api/apiUtility';

const initialState = {
  items: [],
  loading: false,
  error: null,
};

export const fetchAlerts = createAsyncThunk(
  'alerts/fetchAlerts',
  async ({ restaurantId, token }, { rejectWithValue }) => {
    try {
      const response = await getUserAlerts(restaurantId, token);
      
      // Handle the new response format where data.data is an array of review objects
      if (response.data && response.data.type === 'bad_review' && Array.isArray(response.data.data)) {
        // Transform the response to include full review data
        const alerts = response.data.data.map(reviewData => {
          return {
            type: response.data.type,
            reviewId: reviewData.id, // Keep reviewId for backward compatibility
            seen: false, // Override with false to ensure all alerts show initially
            review: reviewData, // Store the full review object
          };
        });
        
        return alerts;
      } else if (response.data?.data?.reviews && Array.isArray(response.data.data.reviews)) {
        return response.data.data.reviews.map(reviewId => ({
          type: response.data.type,
          reviewId: reviewId,
          seen: false
        }));
      } else {
        console.warn('Unexpected API response format:', response.data);
        
        return [];
      }
    } catch (error) {
      console.error('Error fetching alerts:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const markAsSeenAction = createAsyncThunk(
  'alerts/markAsSeen',
  async ({ restaurantId, reviewId, token }, { rejectWithValue }) => {
    try {
      await markAlertAsSeen(restaurantId, reviewId, token);
      return reviewId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const markManyAsSeenAction = createAsyncThunk(
  'alerts/markManyAsSeen',
  async ({ restaurantId, reviewIds, token }, { rejectWithValue }) => {
    try {
      await markManyAlertsAsSeen(restaurantId, reviewIds, token);
      return reviewIds;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    resetAlerts: (state) => {
      // Reset to initial state
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchAlerts
      .addCase(fetchAlerts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchAlerts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Handle markAsSeen
      .addCase(markAsSeenAction.pending, (state, action) => {
        // Optimistically update the UI
        const reviewId = action.meta.arg.reviewId;
        const alert = state.items.find((alert) => alert.reviewId === reviewId);
        if (alert) {
          alert.seen = true;
        }
      })
      .addCase(markAsSeenAction.fulfilled, (state, action) => {
        // State already updated in pending, no need to update again
      })
      .addCase(markAsSeenAction.rejected, (state, action) => {
        // Revert the optimistic update on failure
        const reviewId = action.meta.arg.reviewId;
        const alert = state.items.find((alert) => alert.reviewId === reviewId);
        if (alert) {
          alert.seen = false;
        }
        state.error = action.payload;
      })

      // Handle markManyAsSeen
      .addCase(markManyAsSeenAction.pending, (state, action) => {
        // Optimistically update the UI
        const reviewIds = action.meta.arg.reviewIds;
        state.items.forEach((alert) => {
          if (reviewIds.includes(alert.reviewId)) {
            alert.seen = true;
          }
        });
      })
      .addCase(markManyAsSeenAction.fulfilled, (state, action) => {
        // State already updated in pending, no need to update again
      })
      .addCase(markManyAsSeenAction.rejected, (state, action) => {
        // Revert the optimistic update on failure
        const reviewIds = action.meta.arg.reviewIds;
        state.items.forEach((alert) => {
          if (reviewIds.includes(alert.reviewId)) {
            alert.seen = false;
          }
        });
        state.error = action.payload;
      });
  },
});

export const { resetAlerts } = alertsSlice.actions;

export default alertsSlice.reducer;

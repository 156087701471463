import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './shared/auth/authSlice';
import loadingReducer from './slices/loadingSlice';
import dashboardReducer from './slices/dashboardSlice';
import locationReducer from './shared/location/locationSlice';
import restaurantReducer from './slices/restaurantSlice';
import reviewsReducer from './shared/reviews/reviewSlice';
import staffReducer from './shared/staff/staffSlice';
import menuReducer from './shared/menu/menuSlice';
import trendsReducer from './slices/trendSlice';
import dateFilterReducer from './shared/dateFilter/dateFilterSlice';
import chartsReducer from './slices/chartsSlice';
import reportsReducer from './slices/reportsSlice';
import alertsReducer from './shared/alerts/alertsSlice';
import homeReducer from './slices/homeSlice';
import profileReducer from './slices/profileSlice';
import keywordsReducer from './slices/keywordsSlice';
import subscriptionReducer from './slices/subscriptionSlice';
import userReducer from './shared/user/userSlice';
import staffRolesReducer from './shared/staff/staffRolesSlice';
import onboardingReducer from './slices/onboardingSlice';
import languageReducer from './slices/languageSlice';

const appReducer = combineReducers({
  auth: authReducer,
  restaurant: restaurantReducer,
  loading: loadingReducer,
  dashboard: dashboardReducer,
  location: locationReducer,
  reviews: reviewsReducer,
  staff: staffReducer,
  menu: menuReducer,
  trend: trendsReducer,
  dateFilter: dateFilterReducer,
  charts: chartsReducer,
  reports: reportsReducer,
  alerts: alertsReducer,
  home: homeReducer,
  profile: profileReducer,
  keywords: keywordsReducer,
  subscription: subscriptionReducer,
  users: userReducer,
  staffRoles: staffRolesReducer,
  onboarding: onboardingReducer,
  language: languageReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout/fulfilled') {
    // Reset state for all reducers when logout action is dispatched
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
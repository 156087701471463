import { useState, useEffect } from 'react';
import { PlusCircle, X } from 'lucide-react'; // Import icons
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../../../components/shadcn/dialog/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import { selectLocations } from '../../../../store/shared/location/locationSelectors';
import { addLocation, deleteLocation } from '../../../../store/shared/location/locationThunks';

const RestaurantEditModal = ({ open, onClose, restaurant, subscriptionTiers, onEdit }) => {
  const dispatch = useDispatch();
  const allLocations = useSelector(selectLocations);
  const availableIntegrations = ['Libro', 'Yelp', 'Google', 'TripAdvisor'];
  const [newIntegration, setNewIntegration] = useState('');

  const isIntegrationsEnabled = (tierId) => {
    if (!tierId) return false;
    const tier = subscriptionTiers.find(t => t.id === tierId);
    return tier?.name === 'Pro' || tier?.name === 'Enterprise';
  };

  const getInitialIntegrations = (restaurant) => {
    return Array.isArray(restaurant.integrations)
      ? restaurant.integrations[0]?.includes(',')
        ? restaurant.integrations[0].split(',').map(i => i.trim())
        : restaurant.integrations
      : restaurant.integrations?.split(',').map(i => i.trim()) || [];
  };
  
  // Initialize formData with all fields including locations
  const [formData, setFormData] = useState({
    name: restaurant.name,
    subscription_tier_id: restaurant.subscription_tier_id || '',
    main_open_ai_model: restaurant.main_open_ai_model || '',
    approval_zapier_url: restaurant.approval_zapier_url || '',
    weekly_report_day: restaurant.weekly_report_day || '',
    additional_system_prompt_instructions: restaurant.additional_system_prompt_instructions || '',
    integrations: getInitialIntegrations(restaurant),
    auto_approve_reviews: restaurant.auto_approve_reviews || false,
    auto_approval_minutes_delay: restaurant.auto_approval_minutes_delay || '',
    locations: [] // Initialize empty array
  });

  const [newLocation, setNewLocation] = useState('');
  
  // Update formData when modal opens or locations change
  useEffect(() => {
    if (open && allLocations) {
      const restaurantLocations = allLocations.filter(
        location => location.restaurant_id === restaurant.id
      );
      setFormData(prev => ({
        ...prev,
        locations: restaurantLocations
      }));
      setNewLocation('');
    }
  }, [open, restaurant.id, allLocations]);

  const handleAddLocation = () => {
    if (newLocation.trim()) {
      setFormData(prev => ({
        ...prev,
        locations: [...prev.locations, {
          id: `temp-${Date.now()}`, // Temporary ID for new locations
          name: newLocation.trim(),
          restaurant_id: restaurant.id,
          isNew: true // Flag to identify new locations
        }]
      }));
      setNewLocation('');
    }
  };

  const handleDeleteLocation = (locationId) => {
    setFormData(prev => ({
      ...prev,
      locations: prev.locations.filter(loc => loc.id !== locationId)
    }));
  };

  const delayOptions = [
    { label: '1 minute', value: 1 },
    { label: '15 minutes', value: 15 },
    { label: '30 minutes', value: 30 },
    { label: '1 hour', value: 60 },
    { label: '4 hours', value: 240 },
    { label: '12 hours', value: 720 },
    { label: '1 day', value: 1440 },
    { label: '3 days', value: 4320 },
    { label: '1 week', value: 10080 }
  ];

  const handleAddIntegration = () => {
    if (newIntegration && !formData.integrations.includes(newIntegration)) {
      setFormData(prev => ({
        ...prev,
        integrations: [...prev.integrations, newIntegration]
      }));
      setNewIntegration('');
    }
  };

  const handleRemoveIntegration = (integration) => {
    setFormData(prev => ({
      ...prev,
      integrations: prev.integrations.filter(i => i !== integration)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Handle existing form fields
      const updatedData = {
        name: formData.name,
        subscription_tier_id: formData.subscription_tier_id,
        approval_zapier_url: formData.approval_zapier_url || '',
      };

      // Only add other fields if they have values and were changed
      if (formData.main_open_ai_model !== restaurant.main_open_ai_model && formData.main_open_ai_model !== '') {
        updatedData.main_open_ai_model = formData.main_open_ai_model;
      }

      if (formData.weekly_report_day !== restaurant.weekly_report_day && formData.weekly_report_day !== '') {
        updatedData.weekly_report_day = Number(formData.weekly_report_day) || null;
      }

      if (formData.additional_system_prompt_instructions !== restaurant.additional_system_prompt_instructions 
          && formData.additional_system_prompt_instructions !== '') {
        updatedData.additional_system_prompt_instructions = formData.additional_system_prompt_instructions;
      }

      // Add auto-approve fields if they've changed
      if (formData.auto_approve_reviews !== restaurant.auto_approve_reviews) {
        updatedData.auto_approve_reviews = formData.auto_approve_reviews;
      }

      if (formData.auto_approval_minutes_delay !== restaurant.auto_approval_minutes_delay) {
        updatedData.auto_approval_minutes_delay = formData.auto_approval_minutes_delay 
          ? Number(formData.auto_approval_minutes_delay) 
          : null;
      }

      // Handle location changes
      const originalLocations = allLocations.filter(
        loc => loc.restaurant_id === restaurant.id
      );
      
      // Find locations to add (ones with isNew flag)
      const locationsToAdd = formData.locations
        .filter(loc => loc.isNew)
        .map(loc => ({
          name: loc.name,
          restaurant_id: restaurant.id
        }));

      // Find locations to delete (ones in original but not in form data)
      const locationsToDelete = originalLocations
        .filter(loc => !formData.locations.find(formLoc => formLoc.id === loc.id))
        .map(loc => loc.id);

      // Update restaurant first
      await onEdit(restaurant.id, updatedData);

      // Then handle location changes
      for (const locationData of locationsToAdd) {
        await dispatch(addLocation(locationData)).unwrap();
      }

      for (const locationId of locationsToDelete) {
        await dispatch(deleteLocation(locationId)).unwrap();
      }

      onClose();
    } catch (error) {
      console.error('Failed to save changes:', error);
      // Handle error (show toast, etc.)
    }
  };

  if (!open) return null;

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl w-[90vw] max-h-[85vh] overflow-y-auto p-6">
        <DialogHeader>
          <DialogTitle>Edit Restaurant</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Name */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
              required
            />
          </div>

          {/* Subscription Tier */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Subscription Tier</label>
            <select
              value={formData.subscription_tier_id || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, subscription_tier_id: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
            >
              <option value="">Select Tier</option>
              {subscriptionTiers.map(tier => (
                <option key={tier.id} value={tier.id}>
                  {tier.name}
                </option>
              ))}
            </select>
          </div>

          {/* OpenAI Model */}
          <div>
            <label className="block text-sm font-medium text-gray-700">OpenAI Model</label>
            <input
              type="text"
              value={formData.main_open_ai_model || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, main_open_ai_model: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
            />
          </div>

          {/* Zapier URL */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Zapier Webhook URL</label>
            <input
              type="text"
              value={formData.approval_zapier_url}
              onChange={(e) => setFormData(prev => ({ ...prev, approval_zapier_url: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
            />
          </div>

          {/* Weekly Report Day */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Weekly Report Day</label>
            <select
              value={formData.weekly_report_day || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, weekly_report_day: e.target.value ? Number(e.target.value) : null }))}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
            >
              <option value="">Select Day</option>
              {[1,2,3,4,5,6,7].map(day => (
                <option key={day} value={day}>
                  {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][day-1]}
                </option>
              ))}
            </select>
          </div>

          {/* Additional Instructions */}
          <div>
            <label className="block text-sm font-medium text-gray-700">Additional System Instructions</label>
            <textarea
              value={formData.additional_system_prompt_instructions || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, additional_system_prompt_instructions: e.target.value }))}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
              rows={4}
            />
          </div>

          {/* Integrations */}
          <div className={!isIntegrationsEnabled(formData.subscription_tier_id) ? 'opacity-50' : ''}>
            <div className="flex justify-between items-center">
              <label className="block text-sm font-medium text-gray-700">Integrations</label>
              {!isIntegrationsEnabled(formData.subscription_tier_id) && (
                <span className="text-xs text-amber-600">Available in Pro and Enterprise tiers</span>
              )}
            </div>
            <div className="flex flex-wrap gap-2 mb-2">
              {formData.integrations.map((integration) => (
                <div 
                  key={integration}
                  className="flex items-center bg-blue-100 px-3 py-1 rounded-full"
                >
                  <span>{integration}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveIntegration(integration)}
                    className="ml-2 text-gray-500 hover:text-red-500"
                    disabled={!isIntegrationsEnabled(formData.subscription_tier_id)}
                  >
                    <X size={14} />
                  </button>
                </div>
              ))}
            </div>
            <div className="flex gap-2">
              <select
                value={newIntegration}
                onChange={(e) => setNewIntegration(e.target.value)}
                className="flex-grow mt-1 block rounded-md border border-gray-300 px-3 py-2"
                disabled={!isIntegrationsEnabled(formData.subscription_tier_id)}
              >
                <option value="">Select Integration</option>
                {availableIntegrations
                  .filter(integration => !formData.integrations.includes(integration))
                  .map(integration => (
                    <option key={integration} value={integration}>
                      {integration}
                    </option>
                  ))}
              </select>
              <button
                type="button"
                onClick={handleAddIntegration}
                disabled={!newIntegration || !isIntegrationsEnabled(formData.subscription_tier_id)}
                className="mt-1 px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <PlusCircle size={20} />
              </button>
            </div>
          </div>

          {/* Auto Approve Reviews - with larger checkbox */}
          <div className="flex items-center space-x-3">
            <input
              type="checkbox"
              id="auto_approve_reviews"
              checked={formData.auto_approve_reviews}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                auto_approve_reviews: e.target.checked 
              }))}
              className="w-5 h-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label 
              htmlFor="auto_approve_reviews" 
              className="text-sm font-medium text-gray-700 select-none"
            >
              Automatically approve reviews
            </label>
          </div>

          {/* Auto Approval Delay */}
          {formData.auto_approve_reviews && (
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Approval Delay
              </label>
              <select
                value={formData.auto_approval_minutes_delay}
                onChange={(e) => setFormData(prev => ({ 
                  ...prev, 
                  auto_approval_minutes_delay: e.target.value 
                }))}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
              >
                <option value="">Select Delay</option>
                {delayOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Locations Section */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Locations</label>
            <div className="flex flex-wrap gap-2 mb-2">
              {formData.locations.map((location) => (
                <div 
                  key={location.id}
                  className="flex items-center bg-blue-100 px-3 py-1 rounded-full"
                >
                  <span>{location.name}</span>
                  <button
                    type="button"
                    onClick={() => handleDeleteLocation(location.id)}
                    className="ml-2 text-gray-500 hover:text-red-500"
                  >
                    <X size={14} />
                  </button>
                </div>
              ))}
            </div>
            <div className="flex gap-2">
              <input
                type="text"
                value={newLocation}
                onChange={(e) => setNewLocation(e.target.value)}
                placeholder="Enter location name"
                className="flex-grow mt-1 block rounded-md border border-gray-300 px-3 py-2"
              />
              <button
                type="button"
                onClick={handleAddLocation}
                disabled={!newLocation.trim()}
                className="mt-1 px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50"
              >
                <PlusCircle size={20} />
              </button>
            </div>
          </div>

          <DialogFooter className="mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              Save Changes
            </button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RestaurantEditModal; 
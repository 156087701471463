import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  selectUserUsername,
  selectUserEmail,
  selectUserFirstName,
  selectUserLastName,
  selectUserRole,
  selectAccessToken,
  selectUserId,
  selectUserLocationId,
  selectUserEnabled,
  selectUserRoleId
} from '../../../store/shared/auth/authSelectors';
import { updateUserProfile } from '../../../store/shared/auth/authSlice';
import { User, Mail, BadgeCheck, Loader2 } from 'lucide-react';
import { useLanguage } from '../../language/hooks/useLanguageHook';

const ProfileTab = () => {
  const { t } = useLanguage();
  const username = useSelector(selectUserUsername);
  const email = useSelector(selectUserEmail);
  const firstName = useSelector(selectUserFirstName);
  const lastName = useSelector(selectUserLastName);
  const role = useSelector(selectUserRole);
  const token = useSelector(selectAccessToken);
  const userId = useSelector(selectUserId);
  const locationId = useSelector(selectUserLocationId);
  const enabled = useSelector(selectUserEnabled);
  const roleId = useSelector(selectUserRoleId);

  const [formData, setFormData] = useState({
    id: userId,
    locationId,
    username,
    enabled,
    firstname: firstName,
    lastname: lastName,
    email,
    roleId: [roleId]
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await updateUserProfile(formData, token);
      dispatch(updateUserProfile(formData));
      setSuccess(true);
      // Clear success message after 3 seconds
      setTimeout(() => setSuccess(false), 3000);
    } catch (err) {
      setError(err.message || 'Failed to update profile');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-4">
      {/* Role Display */}
      <div className="flex items-center space-x-4 p-3 bg-gray-50 dark:bg-gray-800 rounded-lg">
        <BadgeCheck className="h-5 w-5 text-blue-500" />
        <div>
          <p className="text-sm font-medium text-gray-500 dark:text-gray-400">{t('dashboard.accountSettings.profile.role')}</p>
          <p className="text-sm text-gray-900 dark:text-gray-200">{role}</p>
        </div>
      </div>

      {/* Username Field */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          {t('dashboard.accountSettings.profile.username')}
        </label>
        <div className="relative">
          <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            className="pl-10 w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
      </div>

      {/* Email Field */}
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          {t('dashboard.accountSettings.profile.email')}
        </label>
        <div className="relative">
          <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="pl-10 w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
      </div>

      {/* Name Fields */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            {t('dashboard.accountSettings.profile.firstName')}
          </label>
          <input
            type="text"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            {t('dashboard.accountSettings.profile.lastName')}
          </label>
          <input
            type="text"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <div className="text-red-500 text-sm">
          {error}
        </div>
      )}

      {/* Success Message */}
      {success && (
        <div className="text-green-500 text-sm">
          {t('dashboard.accountSettings.profile.updateSuccess')}
        </div>
      )}

      {/* Submit Button */}
      <button
        type="submit"
        disabled={isLoading}
        className="w-full flex items-center justify-center p-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isLoading ? (
          <Loader2 className="h-5 w-5 animate-spin" />
        ) : (
          t('dashboard.accountSettings.profile.saveChanges')
        )}
      </button>
    </form>
  );
};

export default ProfileTab;

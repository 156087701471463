import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  getUsers,
  createUser as createUserApi,
  updateUserProfile,
  deleteUser as deleteUserApi,
  fetchRoles as fetchRolesApi
} from '../../../services/api/apiUtility';

// Async thunks
export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (token) => {
    const response = await getUsers(token);
    return response.data;
  }
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async ({ userData, token }, { rejectWithValue }) => {
    try {
      // Ensure roles is an array of role IDs
      const transformedData = {
        location_id: userData.location_id,
        email: userData.email,
        username: userData.username,
        firstname: userData.firstname,
        lastname: userData.lastname,
        roles: Array.isArray(userData.roles) 
          ? userData.roles.map(role => typeof role === 'string' ? role : role.id)
          : [],
        enabled: userData.enabled === 'active' || userData.enabled === true
      };

      // Validate required fields
      const requiredFields = ['location_id', 'email', 'username', 'firstname', 'lastname'];
      const missingFields = requiredFields.filter(field => !transformedData[field]);
      
      if (missingFields.length > 0 || transformedData.roles.length === 0) {
        return rejectWithValue(`Missing required fields: ${missingFields.join(', ')}${transformedData.roles.length === 0 ? ', roles' : ''}`);
      }

      const response = await createUserApi(transformedData, token);
      
      if (!response.data) {
        return rejectWithValue('Failed to create user: No data received from server');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error creating user:', error);
      return rejectWithValue(error.message || 'Failed to create user');
    }
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async ({ id, userData, token }, { rejectWithValue }) => {
    try {
      // Ensure roles is an array of role IDs and all required fields are present
      const transformedData = {
        id,
        location_id: userData.location_id,
        email: userData.email,
        username: userData.username,
        firstname: userData.firstname,
        lastname: userData.lastname,
        roles: Array.isArray(userData.roles) 
          ? userData.roles.map(role => typeof role === 'string' ? role : role.id)
          : [],
        enabled: userData.enabled === 'active' || userData.enabled === true
      };

      // Validate required fields
      const requiredFields = ['location_id', 'email', 'username', 'firstname', 'lastname'];
      const missingFields = requiredFields.filter(field => !transformedData[field]);
      
      if (missingFields.length > 0 || transformedData.roles.length === 0) {
        return rejectWithValue(`Missing required fields: ${missingFields.join(', ')}${transformedData.roles.length === 0 ? ', roles' : ''}`);
      }

      const response = await updateUserProfile(transformedData, token);
      
      if (!response.data) {
        return rejectWithValue('Failed to update user: No data received from server');
      }
      
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
      return rejectWithValue(error.message || 'Failed to update user');
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async ({ id, token }) => {
    await deleteUserApi(id, token);
    return id;
  }
);

export const fetchRoles = createAsyncThunk(
  'users/fetchRoles',
  async (token) => {
    const response = await fetchRolesApi(token);
    return response.data;
  }
);

const initialState = {
  users: [],
  roles: [],
  selectedUser: null,
  loading: false,
  error: null
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    clearSelectedUser: (state) => {
      state.selectedUser = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch Users
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload; // This should be the array from response.data
        state.error = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch Roles
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
      })
      // Create User
      .addCase(createUser.fulfilled, (state, action) => {
        state.users.push(action.payload);
      })
      // Update User
      .addCase(updateUser.fulfilled, (state, action) => {
        const index = state.users.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })
      // Delete User
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter(user => user.id !== action.payload);
      });
  }
});

// Selectors
export const selectUsers = (state) => state.users.users;
export const selectRoles = (state) => state.users.roles;
export const selectLoading = (state) => state.users.loading;
export const selectError = (state) => state.users.error;

export const { setSelectedUser, clearSelectedUser } = userSlice.actions;

export default userSlice.reducer;

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from '../hooks/useLanguageHook';
import { useSelector } from 'react-redux';

const LanguageRouter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useSelector((state) => state.language);
  const { changeLanguage } = useLanguage();

  useEffect(() => {
    // Extract language from URL path
    const pathLang = location.pathname.split('/')[1];
    const validLangs = ['en', 'fr']; // Add more as needed
    
    if (validLangs.includes(pathLang)) {
      if (pathLang !== language) {
        changeLanguage(pathLang);
      }
    } else {
      // Redirect to default language
      navigate(`/${language}${location.pathname}`, { replace: true });
    }
  }, [location.pathname, language, changeLanguage, navigate]);

  return children;
};

export default LanguageRouter;

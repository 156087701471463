// src/features/auth/components/Login.js
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../store/shared/auth/authThunks';
import { selectAuth } from '../../../store/shared/auth/authSelectors';
import { useLanguage } from "../../language/hooks/useLanguageHook";

const Login = () => {
  const { lang } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken, userRole, isLoading } = useSelector(selectAuth);
  const { t } = useLanguage();

  useEffect(() => {
    if (accessToken) {
      if (userRole === "SuperAdmin") {
        navigate(`/${lang}/superadmin`, { replace: true });
      } else {
        navigate(`/${lang}/dashboard/home`, { replace: true });
      }
    }
  }, [accessToken, userRole, navigate, lang]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(null);
  
    try {
      const resultAction = await dispatch(loginUser({ email, password }));
      if (loginUser.rejected.match(resultAction)) {
        throw new Error(resultAction.payload || "Authentication failed");
      }
    } catch (err) {
      setError(err.message || "An error occurred during login.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <div className="w-full max-w-md">
        <form onSubmit={handleLogin} className="bg-white rounded-lg shadow-lg px-8 pt-6 pb-8 mb-4">
          <div className="mb-6">
            <h2 className="text-2xl font-bold text-gray-900 text-center">{t("public.login.title")}</h2>
            <p className="text-center text-gray-600 mt-2">{t("public.login.subtitle")}</p>
          </div>
          
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-600 rounded-md text-sm">
              {error}
            </div>
          )}
          
          <div className="mb-4">
            <label className="block text-gray-900 text-sm font-medium mb-2">
              {t("public.login.form.email.label")}
            </label>
            <input
              type="email"

              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white text-gray-900 placeholder-gray-500"
              placeholder={t("public.login.form.email.placeholder")}
              required
              disabled={isLoading}
            />

          </div>
          
          <div className="mb-6">
            <label className="block text-gray-900 text-sm font-medium mb-2">
              {t("public.login.form.password.label")}
            </label>
            <input
              type="password"

              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white text-gray-900 placeholder-gray-500"
              placeholder={t("public.login.form.password.placeholder")}
              required
              disabled={isLoading}
            />
          </div>
          
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="flex items-center justify-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {t("public.login.form.submit.loading")}

              </span>
            ) : (
              t("public.login.form.submit.label")
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
import React, { useContext, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useLocation as useRouterLocation,
} from "react-router-dom";
import { ThemeContext } from "../../../contexts/ThemeContext";
import {
  locationSelectors,
  setSelectedLocation,
} from "../../../store/shared/location/locationSlice";
import { locationChanged } from "../../../store/slices/dashboardSlice";
import {
  LogOut,
  MapPin,
  ChevronDown,
  Sun,
  Moon,
  ChevronUp,
  User,
  MapPinned,
  Crown,
  MoreHorizontal,
  Globe,
  Network,
  Layers
} from "lucide-react";
import { useAuth } from "../../auth/hooks/useAuth";
import PropTypes from "prop-types";
import useMediaQuery from "../../../hooks/useMediaQuery";
import AccountSettingsModal from "../../account/components/AccountSettingsModal";
import { useLanguage } from '../../language/hooks/useLanguageHook';
import { fetchAlerts } from "../../../store/shared/alerts/alertsSlice";
import { selectAlerts } from "../../../store/shared/alerts/alertSelectors";
import { selectActiveAlertsCount } from "../../../store/shared/alerts/alertSelectors";
import logoImage from '../../../assets/images/FeedBacked4_NBG.png';
import { getNavigationItems } from "../../../utils/navigationConfig";
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "../../../components/shadcn/collapsible/Collapsible";

// Import shadcn Sidebar components
import Sidebar from "../../../components/shadcn/sidebar/Sidebar";
import SidebarItem from "../../../components/shadcn/sidebar/SidebarItem";
import SidebarGroup from "../../../components/shadcn/sidebar/SidebarGroup";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "../../../components/shadcn/dropdown/DropdownMenu";
import {
  selectUserRole,
  selectUserFirstName,
  selectUserLastName,
  selectUserEmail,
  selectSubscriptionTier,
  selectSelectedRestaurant,
  selectAccessToken
} from "../../../store/shared/auth/authSelectors";

const DROPDOWN_WIDTH = "w-56";
const MOBILE_DROPDOWN_WIDTH = "w-[calc(100vw-2rem)]";

const Logo = ({ className = "" }) => (
  <img 
    src={logoImage} 
    alt="FeedBacked Logo" 
    className={`h-7 w-7 ${className} object-contain`}
  />
);

const DashboardIntegratedSidebar = () => {
  const { t, language } = useLanguage();
  const dispatch = useDispatch();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const routerLocation = useRouterLocation();
  const isDark = theme === "dark";
  const [isLocationExpanded, setIsLocationExpanded] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [alertsFetched, setAlertsFetched] = useState(false);

  // Redux selectors
  const locations = useSelector(locationSelectors.selectLocations);
  const selectedLocation = useSelector(locationSelectors.selectSelectedLocation);
  const userRole = useSelector(selectUserRole);
  const firstName = useSelector(selectUserFirstName);
  const lastName = useSelector(selectUserLastName);
  const email = useSelector(selectUserEmail);
  const activeAlertsCount = useSelector(selectActiveAlertsCount);
  const subscriptionTier = useSelector(selectSubscriptionTier);
  const selectedRestaurant = useSelector(selectSelectedRestaurant);
  const accessToken = useSelector(selectAccessToken);
  const allAlerts = useSelector(selectAlerts);

  // Get navigation items based on role and subscription tier
  const navigationItems = getNavigationItems(userRole, subscriptionTier, language);

  // UI Avatars URL with customization
  const avatarUrl = `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=${
    isDark ? "2563eb" : "3b82f6"
  }&color=fff&bold=true&format=svg`;

  // Memoize the alerts count calculation for better performance
  const unseenAlertsCount = useMemo(() => {
    // If there's a direct count from Redux, use it
    if (typeof activeAlertsCount === 'number') {
      return activeAlertsCount;
    }
    
    // Otherwise count from raw alerts data
    if (!Array.isArray(allAlerts)) return 0;
    
    // Count alerts that aren't seen
    return allAlerts.filter(alert => !alert.seen).length;
  }, [allAlerts, activeAlertsCount]);

  // Listen for route changes - this helps update alerts when returning from alerts page
  useEffect(() => {
    // When user navigates back from alerts page, refresh the alerts
    if (selectedRestaurant?.id && accessToken && 
        !routerLocation.pathname.includes('/alerts')) {
      dispatch(fetchAlerts({ restaurantId: selectedRestaurant.id, token: accessToken }));
    }
  }, [routerLocation.pathname, dispatch, selectedRestaurant, accessToken]);

  // Refetch alerts periodically to ensure count is up-to-date
  useEffect(() => {
    if (selectedRestaurant?.id && accessToken && !alertsFetched) {
      // Initial fetch
      dispatch(fetchAlerts({ restaurantId: selectedRestaurant.id, token: accessToken }))
        .then(() => {
          setAlertsFetched(true);
        })
        .catch(error => {
          // Keep error logging for production troubleshooting
          console.error("Error fetching alerts:", error);
        });
      
      // Set up periodic refetch (every 5 minutes)
      const intervalId = setInterval(() => {
        dispatch(fetchAlerts({ restaurantId: selectedRestaurant.id, token: accessToken }));
      }, 5 * 60 * 1000);
      
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [dispatch, selectedRestaurant, accessToken, alertsFetched]);

  const handleLocationSelect = async (loc) => {
    if (selectedLocation?.id === loc.id) return;

    // Dispatch both actions
    await dispatch(setSelectedLocation(loc));
    await dispatch(locationChanged(loc?.id || "all"));
  };

  const handleLogout = async () => {
    await logout();
    navigate(`/${language}/login`);
  };

  // Update the SidebarItem to show enhanced alert count badge
  const renderSidebarItem = (link) => {
    // Check if this item is an alerts item by feature or label
    const isAlertsItem = 
      link.feature === 'alerts' || 
      (link.label && link.label.toLowerCase().includes('alert'));
    
    return (
      <SidebarItem
        key={link.path}
        to={link.path}
        icon={link.icon}
        label={t(link.label)}
        description={t(link.description)}
        className={`flex items-center p-4 rounded-md hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors ${
          routerLocation.pathname === link.path
            ? "text-blue-600 dark:text-blue-400"
            : "text-gray-600 dark:text-gray-400"
        }`}
      >
        {/* Show alert badge if this is an alerts item and there are unseen alerts */}
        {isAlertsItem && unseenAlertsCount > 0 && (
          <div className="ml-auto">
            <span className={`${
              unseenAlertsCount > 5 
                ? "bg-red-100 dark:bg-red-900 text-red-600 dark:text-red-400" 
                : "bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-400"
            } px-2 py-0.5 text-xs font-medium rounded-full flex items-center`}>
              {unseenAlertsCount}
            </span>
          </div>
        )}
        {/* Show Pro badge for features not available in current tier */}
        {link.needsProBadge && !isAlertsItem && (
          <div className="ml-auto flex items-center">
            <span className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-white px-2 py-0.5 text-[10px] font-medium rounded-full flex items-center gap-1">
              <Crown size={10} />
              PRO
            </span>
          </div>
        )}
      </SidebarItem>
    );
  };

  return (
    <>
      <Sidebar
        className={`w-64 h-full overflow-y-auto ${
          isDark ? "bg-gray-900" : "bg-white"
        } border-r border-gray-200 dark:border-gray-800`}
      >
        {/* Brand Header */}
        <SidebarGroup>
          <div className="flex items-center space-x-3 p-4">
            <Logo />
            <div className="flex flex-col">
              <span className="text-lg font-bold text-blue-600">FeedBacked</span>
              {selectedRestaurant?.name && (
                <div className="flex items-center gap-2">
                  <span className={`text-sm ${isDark ? "text-gray-400" : "text-gray-500"}`}>
                    {selectedRestaurant.name}
                  </span>
                  {subscriptionTier && (
                    <span className={`text-xs px-2 py-0.5 rounded-full ${
                      subscriptionTier === 'Enterprise'
                        ? 'bg-gradient-to-r from-purple-400 to-purple-600 text-white'
                        : subscriptionTier === 'Pro' 
                        ? 'bg-gradient-to-r from-yellow-400 to-yellow-600 text-white' 
                        : subscriptionTier === 'Essential'
                        ? 'bg-blue-100 text-blue-600 dark:bg-blue-900 dark:text-blue-400'
                        : 'bg-gray-100 text-gray-600 dark:bg-gray-800 dark:text-gray-400'
                    }`}>
                      {subscriptionTier}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </SidebarGroup>

        {/* Divider */}
        <hr
          className={`${
            theme === "dark" ? "border-gray-800" : "border-gray-200"
          } my-2`}
        />

        {/* Location Section */}
        <SidebarGroup>
          <DropdownMenu onOpenChange={setIsLocationOpen}>
            <DropdownMenuTrigger asChild>
              <button className="flex items-center justify-between w-full px-4 py-2.5 text-sm text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors">
                <div className="flex items-center gap-2">
                  <MapPinned
                    size={16}
                    className="text-gray-500 dark:text-gray-400"
                  />
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {t('dashboard.sidebar.common.currentLocation')}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="font-medium">
                    {selectedLocation?.name || t('dashboard.sidebar.all')}
                  </span>
                  <ChevronDown
                    size={16}
                    className={`shrink-0 opacity-50 transform transition-transform duration-200 ${
                      isLocationOpen ? "-rotate-90" : ""
                    }`}
                  />
                </div>
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              side={isMobile ? "bottom" : "right"}
              align={isMobile ? "center" : "start"}
              sideOffset={8}
              className={`${
                isMobile ? MOBILE_DROPDOWN_WIDTH : DROPDOWN_WIDTH
              } max-h-[300px] overflow-y-auto`}
            >
              {locations.map((loc) => (
                <DropdownMenuItem
                  key={loc.id}
                  onClick={() => handleLocationSelect(loc)}
                  className={`${
                    selectedLocation?.id === loc.id
                      ? "bg-blue-50 text-blue-600 dark:bg-blue-900/50 dark:text-blue-400"
                      : ""
                  } ${isMobile ? "py-3" : ""} flex items-center gap-2`}
                >
                  <MapPin
                    size={14}
                    className={
                      selectedLocation?.id === loc.id
                        ? "text-blue-500"
                        : "text-gray-400"
                    }
                  />
                  {loc.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarGroup>

        
       

        {/* Primary Navigation Items (without section title) */}
        <SidebarGroup>
          {navigationItems
            .filter((item) => ["home", "agent", "alerts"].includes(item.feature))
            .sort((a, b) => {
              const order = ["home", "agent", "alerts"];
              return order.indexOf(a.feature) - order.indexOf(b.feature);
            })
            .map(renderSidebarItem)}
        </SidebarGroup>

        {/* Section with titles */}
        <SidebarGroup className="space-y-6">
          {/* Feedback Section */}
          <div className="pt-6">
            <h3 className="mb-3 px-4 text-xs font-semibold text-gray-400 dark:text-gray-400">
              {t('dashboard.sidebar.sections.feedback')}
            </h3>
            {navigationItems
              .filter((item) => ["reviews", "staff", "profiles", "menu"].includes(item.feature))
              .map(renderSidebarItem)}
            <Collapsible>
              <CollapsibleTrigger asChild>
                <button className="flex items-center justify-between w-full px-[12px] py-[8px] hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors">
                  <div className="flex items-center gap-2">
                    <Globe size={16} className="text-gray-500 dark:text-gray-400 shrink-0" />
                    <span className="text-sm text-gray-600 dark:text-gray-300 font-semibold">{t('dashboard.sidebar.nav.socials')}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    {subscriptionTier !== 'Pro' && subscriptionTier !== 'Enterprise' && (
                      <span className="bg-gradient-to-r from-yellow-400 to-yellow-600 text-white px-2 py-0.5 text-[10px] font-medium rounded-full flex items-center gap-1">
                        <Crown size={10} />
                        PRO
                      </span>
                    )}
                    <ChevronDown
                      size={16}
                      className="shrink-0 opacity-50 transform transition-transform duration-200"
                    />
                  </div>
                </button>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <div className="space-y-1">
                  {navigationItems
                    .filter((item) => item.feature === "posts" || item.feature === "platforms")
                    .map((item) => (
                      <SidebarItem
                        key={item.path}
                        to={item.path}
                        icon={item.icon}
                        label={t(item.label)}
                        className={`flex items-center justify-between w-full pl-10 px-[12px] py-[8px] text-sm ${
                          routerLocation.pathname === item.path
                            ? "text-blue-600 dark:text-blue-400"
                            : "text-gray-600 dark:text-gray-300"
                        } hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors`}
                      />
                    ))}
                </div>
              </CollapsibleContent>
            </Collapsible>
          </div>

          {/* Analytics Section */}
          <div>
            <h3 className="mb-3 px-4 text-xs font-semibold text-gray-400 dark:text-gray-400">
              {t('dashboard.sidebar.sections.analytics')}
            </h3>
            {navigationItems
              .filter((item) => ["trends", "graphs", "keywords", "reports"].includes(item.feature))
              .map(renderSidebarItem)}
          </div>

          {/* Integrations Section - Only show if there are items */}
          {navigationItems.some(item => item.feature === "reservations") && (
            <div>
              <h3 className="mb-3 px-4 text-xs font-semibold text-gray-400 dark:text-gray-400">
                Integrations
              </h3>
              {navigationItems
                .filter((item) => item.feature === "reservations")
                .map(renderSidebarItem)}
            </div>
          )}

          {/* Settings Section */}
          <div>
            <h3 className="mb-3 px-4 text-xs font-semibold text-gray-400 dark:text-gray-400">
              {t('dashboard.sidebar.sections.settings')}
            </h3>
            {navigationItems
              .filter((item) => ["administration"].includes(item.feature))
              .map(renderSidebarItem)}
          </div>
        </SidebarGroup>

        {/* User Controls in Footer */}
        <SidebarGroup className="mt-auto p-2 border-t border-gray-200 dark:border-gray-800">
          <DropdownMenu onOpenChange={setIsUserMenuOpen}>
            <DropdownMenuTrigger asChild>
              <button className="flex flex-col w-full px-2 py-1.5 rounded-lg transition-colors hover:bg-gray-100 dark:hover:bg-gray-800">
                <div className="flex items-center justify-between w-full space-x-2">
                  <div className="flex items-center gap-2 min-w-0">
                    <div
                      className={`h-7 w-7 rounded-lg overflow-hidden flex items-center justify-center bg-${
                        isDark ? "blue-600" : "blue-500"
                      } ring-1 ring-gray-200 dark:ring-gray-800`}
                    >
                      <img
                        src={avatarUrl}
                        alt={`${firstName} ${lastName}`}
                        className="h-full w-full"
                      />
                    </div>
                    <div className="flex flex-col text-left leading-tight min-w-0">
                      <span className="text-[11px] text-gray-500 dark:text-gray-400">
                        {userRole}
                      </span>
                      <span className="font-medium text-sm truncate">
                        {firstName} {lastName}
                      </span>
                      <span className="text-xs text-gray-500 dark:text-gray-400 truncate">
                        {email}
                      </span>
                    </div>
                  </div>
                  <ChevronDown
                    size={16}
                    className={`shrink-0 opacity-50 transform transition-transform duration-200 ${
                      isUserMenuOpen ? "-rotate-90" : ""
                    }`}
                  />
                </div>
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              side={isMobile ? "top" : "right"}
              align={isMobile ? "center" : "start"}
              sideOffset={8}
              className={`${
                isMobile ? MOBILE_DROPDOWN_WIDTH : DROPDOWN_WIDTH
              } max-h-[300px] overflow-y-auto mb-4`}
            >
              {/* Account Info Header */}
              <div className="flex items-center gap-2 p-2">
                <div
                  className={`h-8 w-8 rounded-lg overflow-hidden flex items-center justify-center bg-${
                    isDark ? "blue-600" : "blue-500"
                  } ring-1 ring-gray-200 dark:ring-gray-800`}
                >
                  <img
                    src={avatarUrl}
                    alt={`${firstName} ${lastName}`}
                    className="h-full w-full"
                  />
                </div>
                <div className="flex flex-col space-y-1">
                  <p className="text-sm font-medium leading-none">
                    {firstName} {lastName}
                  </p>
                  <p className="text-xs leading-none text-gray-500 dark:text-gray-400">
                    {email}
                  </p>
                </div>
              </div>

              {/* Separator */}
              <DropdownMenuSeparator />

              {/* Menu Items */}
              <DropdownMenuItem
                onClick={() => {
                  setIsAccountModalOpen(true);
                  setIsUserMenuOpen(false);
                }}
              >
                <div className="flex items-center">
                  <User className="mr-2 h-4 w-4" />
                  <span>{t('dashboard.sidebar.account.title')}</span>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={toggleTheme}>
                <div className="flex items-center">
                  {isDark ? (
                    <Sun className="mr-2 h-4 w-4" />
                  ) : (
                    <Moon className="mr-2 h-4 w-4" />
                  )}
                  <span>{t(`dashboard.sidebar.theme.${isDark ? 'light' : 'dark'}`)}</span>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={handleLogout}
                className="text-red-600 dark:text-red-400"
              >
                <div className="flex items-center">
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>{t('dashboard.sidebar.auth.logout')}</span>
                </div>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarGroup>
      </Sidebar>

      <AccountSettingsModal 
        isOpen={isAccountModalOpen}
        onClose={() => setIsAccountModalOpen(false)}
      />
    </>
  );
};

DashboardIntegratedSidebar.propTypes = {
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    })
  ),
};

DashboardIntegratedSidebar.defaultProps = {
  navigationItems: [],
};

export default DashboardIntegratedSidebar;

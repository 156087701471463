import React from 'react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '../../../../components/shadcn/dropdown/DropdownMenu';
import { ChevronDown } from 'lucide-react';

const UserRoleSelector = ({ selectedRoles, onChange, availableRoles }) => {
  const handleRoleChange = (roleId) => {
    onChange([roleId]);
  };

  const selectedRoleName = selectedRoles.length > 0
    ? availableRoles.find(role => role.id === selectedRoles[0])?.name
    || availableRoles.find(role => role.id === selectedRoles[0]?.id)?.name
    : null;

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Role
      </label>
      <DropdownMenu>
        <DropdownMenuTrigger className="w-full flex items-center justify-between px-3 py-2 border rounded-md border-gray-300 bg-white text-sm">
          {selectedRoleName || 'Select a role'}
          <ChevronDown className="h-4 w-4 opacity-50" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full min-w-[200px]">
          {availableRoles.map((role) => (
            <DropdownMenuItem
              key={role.id}
              onClick={() => handleRoleChange(role.id)}
              className="cursor-pointer"
            >
              {role.name}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default UserRoleSelector; 
import React from 'react';
import { Bot, BookOpen, Clock, User, ChevronRight } from 'lucide-react';
import { useLanguage } from "../../language/hooks/useLanguageHook";

const BlogPage = () => {
  const { t, translations } = useLanguage();
  if (!translations) {
    return <div>Loading translations...</div>;
  }

  const posts = t("public.blog.posts", { returnObjects: true });

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6 text-center">
            {t("public.blog.hero.title")}
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto text-center mb-12">
            {t("public.blog.hero.subtitle")}
          </p>
        </div>
      </div>

      {/* Blog Posts Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post, index) => (
            <article key={index} className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
              <div className="p-6">
                <div className="text-blue-600 text-sm font-medium mb-2">{post.category}</div>
                <h2 className="text-xl font-bold text-gray-900 mb-3 hover:text-blue-600 transition-colors">
                  {post.title}
                </h2>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <div className="flex items-center justify-between text-sm text-gray-500">
                  <div className="flex items-center">
                    <User size={16} className="mr-2" />
                    {post.author}
                  </div>
                  <div className="flex items-center">
                    <Clock size={16} className="mr-2" />
                    {post.readTime}
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>

        {/* Newsletter Section */}
        <div className="mt-16 bg-blue-600 rounded-xl p-8 text-white">
          <div className="max-w-2xl mx-auto text-center">
            <h3 className="text-2xl font-bold mb-4">{t("public.blog.newsletter.title")}</h3>
            <p className="mb-6">{t("public.blog.newsletter.subtitle")}</p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <input
                type="email"
                placeholder={t("public.blog.newsletter.emailPlaceholder")}
                className="px-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 text-gray-900 w-full sm:w-auto"
              />
              <button className="px-6 py-3 bg-white text-blue-600 rounded-lg font-medium hover:bg-blue-50 transition-colors">
                {t("public.blog.newsletter.subscribeButton")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
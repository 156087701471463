import React, { useState, useEffect } from "react";
import {
  Bot,
  Star,
  Users,
  Award,
  ArrowRight,
  MessageSquare,
  ChevronDown,
  Play,
  Check,
  Shield,
  Clock,
  DollarSign,
} from "lucide-react";
import MainPageNavbar from "../../../components/common/MainPageNavbar";
import FeatureCard from "../../../components/common/FeatureCard";
import NetworkBackground from "../../../components/common/NetworkBackground";
import { useNavigate } from 'react-router-dom';
import { useLanguage } from "../../language/hooks/useLanguageHook";

// Import platform logos
import GoogleLogo from '../../../assets/images/google-color.png';
import YelpLogo from '../../../assets/images/Yelp-Emblem.png';
import TripAdvisorLogo from '../../../assets/images/tripadvisor-icon.png';
import OpenTableLogo from '../../../assets/images/Opentable.png';

// Add global styles for animations
const styles = `
  @keyframes fadeInScale {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .feature-card {
    opacity: 0;
    animation: fadeInScale 0.5s ease-out forwards;
  }
`;

const getFeaturesList = (t, cardKey) => {
  const features = t(`public.landing.features.cards.${cardKey}.features`, { returnObjects: true });
  return Array.isArray(features) ? features : [];
};

const LandingPage = () => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  
  // Add ROI calculator state
  const [roiInputs, setRoiInputs] = useState({
    monthlyReviews: 100,
    responseTime: 24,
    staffCost: 25
  });

  // Calculate ROI metrics
  const calculateROI = () => {
    const { monthlyReviews, responseTime, staffCost } = roiInputs;

    // If any inputs are invalid or negative, return zeros
    if (responseTime <= 0 || isNaN(responseTime)) {
      return {
        timeSaved: "0",
        costSaved: "0",
        responseImprovement: "0"
      };
    }

    // Calculate metrics
    const timePerReview = responseTime / 60; // Convert to hours
    const totalTimeSaved = Math.round(monthlyReviews * timePerReview * 0.75); // 75% time reduction
    const monthlyCostSaved = Math.round(totalTimeSaved * staffCost);
    const responseImprovement = Math.round((responseTime - (responseTime * 0.25)) / responseTime * 100);

    return {
      timeSaved: totalTimeSaved.toString(),
      costSaved: monthlyCostSaved.toString(),
      responseImprovement: responseImprovement.toString()
    };
  };

  const handleInputChange = (field, value) => {
    // Remove non-numeric characters except decimal point
    const numericValue = value.replace(/[^\d.]/g, '');
    
    // Additional validation
    let finalValue = numericValue;
    
    // Prevent invalid numbers
    if (isNaN(Number(finalValue))) {
      finalValue = '0';
    }
    
    // For all fields, allow any positive number including zero
    if (Number(finalValue) < 0) {
      finalValue = '0';
    }
    
    setRoiInputs(prev => ({
      ...prev,
      [field]: finalValue
    }));
  };

  const roi = calculateROI();

  // Add scroll animation logic
  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const animatedElements = document.querySelectorAll('.scroll-fade-in, .scroll-slide-in, .scroll-scale-in');
    
    animatedElements.forEach(el => observer.observe(el));

    return () => observer.disconnect();
  }, []);

  const testimonials = [
    {
      text: "FeedBacked transformed how we handle customer feedback. Our response time improved by 300%!",
      author: "Sarah Chen",
      role: "Owner, The Golden Plate",
      image: "/api/placeholder/64/64",
    },
    {
      text: "The AI-powered responses are incredibly personalized. Our customers feel heard and valued.",
      author: "Michael Rodriguez",
      role: "Manager, Bistro 54",
      image: "/api/placeholder/64/64",
    },
    {
      text: "The analytics helped us identify and fix key issues in our service. 5-star ratings up by 45%!",
      author: "Emma Thompson",
      role: "Director, Fine Dining Co",
      image: "/api/placeholder/64/64",
    },
  ];

  const StatCard = ({ value, label, sublabel }) => (
    <div className="transform hover:scale-105 transition-transform">
      <div className="text-5xl font-bold mb-2">{value}</div>
      <div className="text-xl mb-1">{label}</div>
      <div className="text-blue-200 text-sm">{sublabel}</div>
    </div>
  );

  const handleStartFreeTrial = () => {
    navigate('/onboarding');
  };

  const trustedBy = [
    '/logos/restaurant1.png',
    '/logos/restaurant2.png',
    '/logos/restaurant3.png',
    '/logos/restaurant4.png',
    '/logos/restaurant5.png',
  ];

  const featureCards = [
    {
      icon: MessageSquare,
      title: t("public.landing.features.cards.responses.title"),
      description: t("public.landing.features.cards.responses.description"),
      highlight: t("public.landing.features.cards.responses.highlight"),
      features: getFeaturesList(t, "responses")
    },
    {
      icon: Users,
      title: t("public.landing.features.cards.performance.title"),
      description: t("public.landing.features.cards.performance.description"),
      highlight: t("public.landing.features.cards.performance.highlight"),
      features: getFeaturesList(t, "performance")
    },
    {
      icon: Star,
      title: t("public.landing.features.cards.intelligence.title"),
      description: t("public.landing.features.cards.intelligence.description"),
      highlight: t("public.landing.features.cards.intelligence.highlight"),
      features: getFeaturesList(t, "intelligence")
    }
  ];


  return (
    <div className="min-h-screen overflow-x-hidden">
      <div className="relative min-h-screen">
        {/* Background Container */}
        <div className="absolute inset-0">
          <NetworkBackground
            color="rgba(37, 99, 235, 0.3)"
            pointColor="rgba(37, 99, 235, 0.7)"
            pointCount={50}
            maxDistance={500}
            speedFactor={0.2}
            backgroundColor="rgb(243, 244, 255)"
            pointSize={2}
            lineWidth={1}
            className="!fixed"
          />
          {/* Enhanced Gradient Overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-white/50 to-white/90" />
        </div>

        {/* Content */}
        <div className="relative pt-20 px-6">
          <div className="max-w-7xl mx-auto py-24">
            {/* Enhanced Trust Badge */}
            {/* <div className="flex items-center justify-center mb-8 space-x-4">
              <div className="flex items-center space-x-2 bg-green-50 text-green-700 px-4 py-2 rounded-full animate-fade-in">
                <Shield className="h-5 w-5" />
                <span className="text-sm font-medium">
                  {t("public.landing.hero.trustBadges.restaurants")}
                </span>
              </div>
              <div className="flex items-center space-x-2 bg-blue-50 text-blue-700 px-4 py-2 rounded-full animate-fade-in delay-100">
                <Star className="h-5 w-5" />
                <span className="text-sm font-medium">
                  {t("public.landing.hero.trustBadges.rating")}
                </span>
              </div>
              <div className="flex items-center space-x-2 bg-purple-50 text-purple-700 px-4 py-2 rounded-full animate-fade-in delay-200">
                <Award className="h-5 w-5" />
                <span className="text-sm font-medium">
                  {t("public.landing.hero.trustBadges.position")}
                </span>
              </div>
            </div> */}

            {/* Split Hero Layout */}
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <h1 className="text-6xl font-bold text-gray-900 mb-8 flex flex-col items-start hero-text">
                  <span className="ml-0">{t("public.landing.hero.title.line1")}</span>
                  <span className="ml-8">{t("public.landing.hero.title.line2")}</span>
                  <span className="ml-16 text-blue-600 relative inline-block">
                    {t("public.landing.hero.title.line3")}
                    <svg className="absolute -bottom-2 left-0 w-full" height="8" viewBox="0 0 200 8">
                      <path d="M0 4C50 4 50 1 100 1S150 7 200 7" fill="none" stroke="#2563EB" strokeWidth="2" />
                    </svg>
                  </span>
                </h1>
                
                <p className="text-xl text-gray-600 mb-8 ml-16 max-w-xl hero-description">
                  {t("public.landing.hero.description")}
                </p>

                <div className="flex space-x-4 mb-8">
                  <button 
                    className="bg-blue-600 text-white px-8 py-4 rounded-lg hover:bg-blue-700 transition-all transform hover:scale-105 hover:shadow-lg flex items-center backdrop-blur-sm"
                    onClick={handleStartFreeTrial}
                  >
                    {t("public.landing.hero.buttons.trial")}
                    <ArrowRight className="ml-2 h-5 w-5" />
                  </button>
                  <button
                    className="bg-white/80 text-gray-900 px-8 py-4 rounded-lg border-2 border-gray-200 hover:border-blue-600 transition-all flex items-center backdrop-blur-sm group"
                    onClick={() => setIsVideoModalOpen(true)}
                  >
                    <div className="bg-blue-100 rounded-full p-1 mr-2 group-hover:bg-blue-200 transition-colors">
                      <Play className="h-4 w-4 text-blue-600" />
                    </div>
                    {t("public.landing.hero.buttons.demo")}
                  </button>
                </div>

                <div className="flex items-center space-x-6">
                  {[
                    { icon: Check, text: t("public.landing.hero.features.trial") },
                    { icon: Check, text: t("public.landing.hero.features.noCard") },
                    { icon: Check, text: t("public.landing.hero.features.cancel") },
                  ].map(({ icon: Icon, text }) => (
                    <div key={text} className="flex items-center text-gray-600">
                      <Icon className="h-5 w-5 text-green-500 mr-2" />
                      {text}s
                    </div>
                  ))}
                </div>
              </div>

              {/* Animated Product Preview */}
              <div className="relative">
                <div className="bg-white rounded-xl shadow-2xl p-6 transform hover:scale-105 transition-transform duration-300">
                  <div className="animate-pulse-slow">
                    <div className="h-8 bg-gray-200 rounded mb-4"></div>
                    <div className="space-y-3">
                      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                    </div>
                  </div>
                  <div className="mt-6 grid grid-cols-3 gap-4">
                    <div className="text-center">
                      <div className="text-2xl font-bold text-blue-600">300%</div>
                      <div className="text-sm text-gray-600">
                        {t("public.landing.hero.preview.stats.fasterResponse")}
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="text-2xl font-bold text-blue-600">1000+</div>
                      <div className="text-sm text-gray-600">
                        {t("public.landing.hero.preview.stats.automatedResponses")}
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="text-2xl font-bold text-blue-600">10hrs+</div>
                      <div className="text-sm text-gray-600">
                        {t("public.landing.hero.preview.stats.timeSavedWeekly")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute -bottom-4 -right-4 bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-medium animate-bounce">
                  {t("public.landing.hero.preview.aiPowered")}
                </div>
              </div>
            </div>

            {/* Trusted By Logos */}
            {/* <div className="text-center">
              <p className="text-sm text-gray-500 mb-4">
                {t("public.landing.trustedBy.title")}
              </p>
              <div className="flex justify-center items-center space-x-8 grayscale opacity-60 hover:grayscale-0 hover:opacity-100 transition-all">
                {trustedBy.map((logo, index) => (
                  <img
                    key={index}
                    src={logo}
                    alt="Restaurant Logo"
                    className="h-12 object-contain"
                  />
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="relative z-10 bg-gray-50">
        <div className="max-w-7xl mx-auto px-6 py-20">
          <div className="text-center mb-16 scroll-fade-in">
            <h2 className="text-4xl font-bold mb-4">
              {t("public.landing.features.title")}
            </h2>
            <p className="text-xl text-gray-600">
              {t("public.landing.features.subtitle")}
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-12">
            {featureCards.map((feature, index) => (
              <div 
                key={feature.title} 
                className="feature-card bg-white rounded-xl p-8 shadow-sm hover:shadow-xl transition-all duration-300"
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                <div className="bg-blue-50 w-16 h-16 rounded-xl flex items-center justify-center mb-6">
                  <feature.icon className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-bold mb-3">{feature.title}</h3>
                <p className="text-gray-600 mb-4">{feature.description}</p>
                <div className="bg-green-50 text-green-700 text-sm font-medium px-4 py-2 rounded-full inline-block mb-6">
                  {feature.highlight}
                </div>
                <ul className="space-y-3">
                  {feature.features.map((item) => (
                    <li key={item} className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-1" />
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Add the style tag at the top of the return statement */}
          <style dangerouslySetInnerHTML={{ __html: styles }} />
        </div>
      </div>

      {/* Interactive Comparison Table */}
      <div className="relative z-10 bg-white py-20">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-16 scroll-fade-in">
            <h2 className="text-4xl font-bold mb-4">
              {t("public.landing.comparison.title")}
            </h2>
            <p className="text-xl text-gray-600">
              {t("public.landing.comparison.subtitle")}
            </p>
          </div>

          <div className="overflow-hidden rounded-xl border border-gray-200 shadow-lg scroll-slide-in">
            <div className="grid grid-cols-3 bg-gray-50">
              <div className="p-6 border-r border-gray-200">
                <div className="text-lg font-semibold text-gray-900">
                  {t("public.landing.comparison.table.headers.features")}
                </div>
              </div>
              <div className="p-6 border-r border-gray-200 bg-blue-50">
                <div className="text-lg font-semibold text-blue-600">
                  {t("public.landing.comparison.table.headers.feedbacked")}
                </div>
              </div>
              <div className="p-6">
                <div className="text-lg font-semibold text-gray-500">
                  {t("public.landing.comparison.table.headers.traditional")}
                </div>
              </div>
            </div>

            {[
              "responseTime",
              "quality",
              "hours",
              "language",
              "sentiment",
              "cost"
            ].map((rowKey, index) => (
              <div 
                key={rowKey}
                className={`grid grid-cols-3 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
              >
                <div className="p-6 border-r border-gray-200 font-medium">
                  {t(`public.landing.comparison.table.rows.${rowKey}.feature`)}
                </div>
                <div className={`p-6 border-r border-gray-200 ${index % 2 === 0 ? 'bg-blue-50' : ''}`}>
                  <div className="flex items-center">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    {t(`public.landing.comparison.table.rows.${rowKey}.feedbacked`)}
                  </div>
                </div>
                <div className="p-6 text-gray-500">
                  {t(`public.landing.comparison.table.rows.${rowKey}.traditional`)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Integration Section */}
      <div className="relative z-10 bg-white py-20">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">{t("public.landing.integration.title")}</h2>
            <p className="text-xl text-gray-600">{t("public.landing.integration.subtitle")}</p>
          </div>
          
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              {
                name: 'Google Reviews',
                logo: GoogleLogo,
                className: 'h-12'
              },
              {
                name: 'Yelp',
                logo: YelpLogo,
                className: 'h-16'
              },
              {
                name: 'TripAdvisor',
                logo: TripAdvisorLogo,
                className: 'h-14'
              },
              {
                name: 'OpenTable',
                logo: OpenTableLogo,
                className: 'h-14'
              }
            ].map((platform) => (
              <div 
                key={platform.name}
                className="bg-white rounded-xl p-8 border border-gray-100 hover:border-blue-500 transition-all duration-300 hover:shadow-lg group"
              >
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="relative w-full flex items-center justify-center mb-4 transform group-hover:scale-105 transition-transform duration-300">
                    <img 
                      src={platform.logo}
                      alt={platform.name}
                      className={`${platform.className} object-contain`}
                    />
                  </div>
                  <p className="font-medium text-gray-700 group-hover:text-blue-600 transition-colors">
                    {platform.name}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="relative z-10 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
        <div className="max-w-7xl mx-auto px-6 py-20">
          <div className="grid md:grid-cols-3 gap-12 text-center">
            {[
              {
                value: t("public.landing.stats.satisfaction.value"),
                label: t("public.landing.stats.satisfaction.label"),
                sublabel: t("public.landing.stats.satisfaction.sublabel"),
              },
              {
                value: t("public.landing.stats.reviews.value"),
                label: t("public.landing.stats.reviews.label"),
                sublabel: t("public.landing.stats.reviews.sublabel"),
              },
              {
                value: t("public.landing.stats.timeSaved.value"),
                label: t("public.landing.stats.timeSaved.label"),
                sublabel: t("public.landing.stats.timeSaved.sublabel"),
              },
            ].map((stat) => (
              <StatCard key={stat.label} {...stat} />
            ))}
          </div>
        </div>
      </div>

      {/* ROI Calculator Section */}
      <div className="relative z-10 bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">
              {t("public.landing.roi.title")}
            </h2>
            <p className="text-xl text-gray-600">
              {t("public.landing.roi.subtitle")}
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-12">
            {/* Input Section */}
            <div className="bg-white rounded-xl p-8 shadow-lg">
              <h3 className="text-xl font-bold mb-6">
                {t("public.landing.roi.metrics.title")}
              </h3>
              <div className="space-y-6">
                <div>
                  <label className="block text-gray-700 mb-2">
                    {t("public.landing.roi.metrics.monthlyReviews.label")}
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      value={roiInputs.monthlyReviews}
                      onChange={(e) =>
                        setRoiInputs({
                          ...roiInputs,
                          monthlyReviews: parseInt(e.target.value) || 0,
                        })
                      }
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">
                      {t("public.landing.roi.metrics.monthlyReviews.suffix")}
                    </span>
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 mb-2">
                    {t("public.landing.roi.metrics.responseTime.label")}
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      value={roiInputs.responseTime}
                      onChange={(e) =>
                        setRoiInputs({
                          ...roiInputs,
                          responseTime: parseInt(e.target.value) || 0,
                        })
                      }
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">
                      {t("public.landing.roi.metrics.responseTime.suffix")}
                    </span>
                  </div>
                </div>

                <div>
                  <label className="block text-gray-700 mb-2">
                    {t("public.landing.roi.metrics.staffCost.label")}
                  </label>
                  <div className="relative">
                    <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">
                      {t("public.landing.roi.metrics.staffCost.prefix")}
                    </span>
                    <input
                      type="number"
                      value={roiInputs.staffCost}
                      onChange={(e) =>
                        setRoiInputs({
                          ...roiInputs,
                          staffCost: parseInt(e.target.value) || 0,
                        })
                      }
                      className="w-full pl-8 pr-16 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <span className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500">
                      {t("public.landing.roi.metrics.staffCost.suffix")}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Results Section */}
            <div className="bg-white rounded-xl p-8 shadow-lg">
              <h3 className="text-xl font-bold mb-6">
                {t("public.landing.roi.results.title")}
              </h3>
              <div className="space-y-6">
                <div>
                  <div className="text-gray-600 mb-2">
                    {t("public.landing.roi.results.timeSaved.label")}
                  </div>
                  <div className="text-3xl font-bold text-blue-600">
                    {calculateROI().timeSaved} {t("public.landing.roi.results.timeSaved.suffix")}
                  </div>
                </div>

                <div>
                  <div className="text-gray-600 mb-2">
                    {t("public.landing.roi.results.costSaved.label")}
                  </div>
                  <div className="text-3xl font-bold text-green-600">
                    {t("public.landing.roi.metrics.staffCost.prefix")}{calculateROI().costSaved}
                  </div>
                </div>

                <div>
                  <div className="text-gray-600 mb-2">
                    {t("public.landing.roi.results.responseImprovement.label")}
                  </div>
                  <div className="text-3xl font-bold text-purple-600">
                    {calculateROI().responseImprovement}%
                  </div>
                </div>

                <button
                  onClick={handleStartFreeTrial}
                  className="w-full bg-blue-600 text-white px-8 py-4 rounded-lg hover:bg-blue-700 transition-all transform hover:scale-105 hover:shadow-lg mt-8"
                >
                  {t("public.landing.roi.results.cta")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dashboard Preview Section */}
      <div className="relative z-10 bg-white py-20 overflow-hidden">
        <div className="max-w-7xl mx-auto px-6">
          <div className="text-center mb-16 scroll-fade-in">
            <h2 className="text-4xl font-bold mb-4">
              {t("public.landing.dashboard.title")}
            </h2>
            <p className="text-xl text-gray-600">
              {t("public.landing.dashboard.subtitle")}
            </p>
          </div>

          <div className="relative">
            {/* Dashboard Preview */}
            <div className="rounded-xl shadow-2xl overflow-hidden scroll-scale-in">
              {/* Window Controls Bar */}
              <div className="bg-gray-900 px-6 py-4 flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="w-3 h-3 rounded-full bg-red-500" />
                  <div className="w-3 h-3 rounded-full bg-yellow-500" />
                  <div className="w-3 h-3 rounded-full bg-green-500" />
                </div>
                <div className="text-gray-400 text-sm">
                  {t("public.landing.dashboard.preview.title")}
                </div>
              </div>

              {/* Dashboard Content */}
              <div className="grid grid-cols-12 gap-6 bg-gray-50 p-6">
                {/* Sidebar */}
                <div className="col-span-3 bg-white rounded-lg p-4 shadow-sm">
                  <div className="space-y-4">
                    <div className="h-8 bg-gray-100 rounded animate-pulse" />
                    <div className="h-8 bg-blue-100 rounded" />
                    <div className="h-8 bg-gray-100 rounded animate-pulse" />
                    <div className="h-8 bg-gray-100 rounded animate-pulse" />
                  </div>
                </div>

                {/* Main Content */}
                <div className="col-span-9 space-y-6">
                  {/* Stats Cards */}
                  <div className="grid grid-cols-3 gap-6">
                    <div className="bg-white rounded-lg p-4 shadow-sm">
                      <div className="text-sm text-gray-600">
                        {t("public.landing.dashboard.preview.stats.reviewsToday.label")}
                      </div>
                      <div className="text-2xl font-bold text-gray-900">
                        {t("public.landing.dashboard.preview.stats.reviewsToday.value")}
                      </div>
                      <div className="text-sm text-green-600">
                        {t("public.landing.dashboard.preview.stats.reviewsToday.trend")}
                      </div>
                    </div>
                    <div className="bg-white rounded-lg p-4 shadow-sm">
                      <div className="text-sm text-gray-600">
                        {t("public.landing.dashboard.preview.stats.responseRate.label")}
                      </div>
                      <div className="text-2xl font-bold text-gray-900">
                        {t("public.landing.dashboard.preview.stats.responseRate.value")}
                      </div>
                      <div className="text-sm text-green-600">
                        {t("public.landing.dashboard.preview.stats.responseRate.trend")}
                      </div>
                    </div>
                    <div className="bg-white rounded-lg p-4 shadow-sm">
                      <div className="text-sm text-gray-600">
                        {t("public.landing.dashboard.preview.stats.avgRating.label")}
                      </div>
                      <div className="text-2xl font-bold text-gray-900">
                        {t("public.landing.dashboard.preview.stats.avgRating.value")}
                      </div>
                      <div className="text-sm text-green-600">
                        {t("public.landing.dashboard.preview.stats.avgRating.trend")}
                      </div>
                    </div>
                  </div>

                  {/* Review List */}
                  <div className="bg-white rounded-lg p-6 shadow-sm">
                    <div className="space-y-6">
                      {[1, 2, 3].map((i) => (
                        <div key={i} className="flex items-start space-x-4 pb-4 border-b border-gray-100">
                          <div className="w-10 h-10 rounded-full bg-gray-100 animate-pulse" />
                          <div className="flex-1 space-y-2">
                            <div className="h-4 bg-gray-100 rounded w-1/4 animate-pulse" />
                            <div className="h-4 bg-gray-100 rounded w-3/4 animate-pulse" />
                            <div className="flex items-center space-x-2">
                              {[1, 2, 3, 4, 5].map((star) => (
                                <Star key={star} className="h-4 w-4 text-yellow-400 fill-current" />
                              ))}
                            </div>
                          </div>
                          <div className="w-32">
                            <div className="h-8 bg-blue-100 rounded" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Feature Callouts */}
            <div className="absolute -right-12 top-1/4 transform translate-x-1/2">
              <div className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-lg">
                {t("public.landing.dashboard.preview.features.analytics")}
              </div>
            </div>
            <div className="absolute -left-12 top-2/3 transform -translate-x-1/2">
              <div className="bg-green-600 text-white px-6 py-3 rounded-lg shadow-lg">
                {t("public.landing.dashboard.preview.features.response")}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="relative z-10 bg-white">
        <div className="max-w-7xl mx-auto px-6 py-20">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">
              {t("public.landing.testimonials.title")}
            </h2>
            <p className="text-xl text-gray-600">
              {t("public.landing.testimonials.subtitle")}
            </p>
          </div>

          {/* Testimonial Cards */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white rounded-xl p-8 shadow-lg">
                <div className="flex items-center mb-6">
                  <img
                    src={testimonial.image}
                    alt={testimonial.author}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <h3 className="font-semibold text-lg">{t(`public.landing.testimonials.items.${index}.author`)}</h3>
                    <p className="text-gray-600">{t(`public.landing.testimonials.items.${index}.role`)}</p>
                  </div>
                </div>
                <div className="flex mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
                  ))}
                </div>
                <p className="text-gray-600 italic">
                  {t(`public.landing.testimonials.items.${index}.text`)}
                </p>
              </div>
            ))}
          </div>

          {/* Results Section */}
          <div className="bg-blue-50 rounded-3xl p-16">
            <h3 className="text-3xl font-bold text-center mb-4">
              {t("public.landing.testimonials.results.title")}
            </h3>
            <p className="text-xl text-gray-600 text-center mb-12">
              {t("public.landing.testimonials.results.subtitle")}
            </p>

            <div className="grid md:grid-cols-4 gap-12">
              {[
                {
                  value: "300%",
                  label: t("public.landing.testimonials.results.stats.responseTime.label")
                },
                {
                  value: "85%",
                  label: t("public.landing.testimonials.results.stats.timeSaved.label")
                },
                {
                  value: "40+",
                  label: t("public.landing.testimonials.results.stats.languages.label")
                },
                {
                  value: "98%",
                  label: t("public.landing.testimonials.results.stats.satisfaction.label")
                }
              ].map((stat, index) => (
                <div key={index} className="text-center">
                  <div className="text-4xl font-bold text-blue-600 mb-2">
                    {stat.value}
                  </div>
                  <div className="text-gray-600">
                    {stat.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Enhanced CTA Section */}
      <div className="relative z-10 bg-gradient-to-r from-blue-600 to-blue-800">
        <div className="max-w-7xl mx-auto px-6 py-24">
          <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-12 text-center">
            <h2 className="text-4xl font-bold text-white mb-6">
              {t("public.landing.finalCta.title.line1")}
              <br />
              <span className="text-blue-200">{t("public.landing.finalCta.title.line2")}</span>
            </h2>
            <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
              {t("public.landing.finalCta.subtitle")}
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6">
              <button 
                onClick={handleStartFreeTrial}
                className="bg-white text-blue-600 px-12 py-6 rounded-lg hover:bg-blue-50 transition-all transform hover:scale-105 hover:shadow-xl text-lg font-semibold w-full sm:w-auto"
              >
                {t("public.landing.finalCta.buttons.trial")}
              </button>
              <button
                onClick={() => setIsVideoModalOpen(true)}
                className="text-white border-2 border-white/30 px-12 py-6 rounded-lg hover:bg-white/10 transition-all text-lg font-semibold w-full sm:w-auto flex items-center justify-center"
              >
                <Play className="h-5 w-5 mr-2" />
                {t("public.landing.finalCta.buttons.demo")}
              </button>
            </div>
            <div className="mt-8 flex justify-center items-center space-x-8 text-blue-100">
              <div className="flex items-center">
                <Check className="h-5 w-5 mr-2" />
                {t("public.landing.finalCta.features.trial")}
              </div>
              <div className="flex items-center">
                <Check className="h-5 w-5 mr-2" />
                {t("public.landing.finalCta.features.noCard")}
              </div>
              <div className="flex items-center">
                <Check className="h-5 w-5 mr-2" />
                {t("public.landing.finalCta.features.cancel")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

import axios from "axios";
import {
  API_ENDPOINTS,
  LIBRO_ENDPOINTS,
  getApiUrl,
  getLibroApiUrl,
  LIBRO_API_HEADERS,
} from "./config";

// Add request cache at the top of the file
const requestCache = new Map();

// Helper function to create cache key
const createCacheKey = (endpoint, method, params) => {
  return JSON.stringify({ endpoint, method, params });
};

export const apiRequest = async (
  endpoint,
  method = "GET",
  data = null,
  params = {},
  headers = {}
) => {
  const url = getApiUrl(endpoint);
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    params,
  };

  if (data) {
    config.data = data;
  }

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

// Helper functions for common API operations
export const redirectToLoginPage = () =>
  (window.location = getApiUrl(API_ENDPOINTS.LOGIN_PAGE));

// Helper functions for common API operations
export const login = (username, password) =>
  apiRequest(API_ENDPOINTS.LOGIN, "POST", { username, password });

export const refreshSession = () =>
  apiRequest(API_ENDPOINTS.REFRESH, "POST", {}, {}, { credentials: "include" });

export const logoutSession = () =>
  apiRequest(API_ENDPOINTS.LOGOUT, "POST", {}, {}, { credentials: "include" });

export const getUsers = (token) =>
  apiRequest(
    API_ENDPOINTS.USERS,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const getUser = (id, token) =>
  apiRequest(
    API_ENDPOINTS.USER.GET(id),
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const updateUserProfile = async (userData, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.USER.UPDATE(userData.id),
      "PUT",
      {
        id: userData.id,
        location_id: userData.location_id,
        username: userData.username,
        enabled: userData.enabled,
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
        roles: userData.roles,
      },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error updating user profile:", error);
    throw error;
  }
};

export const getLocations = (token) =>
  apiRequest(
    API_ENDPOINTS.LOCATION.ALL,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const fetchRoles = async (token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.GET_ROLES,
      "GET",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error fetching roles:", error);
    throw error;
  }
};

export const createUser = (userData, accessToken) =>
  apiRequest(
    API_ENDPOINTS.USER.CREATE,
    "POST",
    {
      location_id: userData.location_id,
      email: userData.email,
      username: userData.username,
      firstname: userData.firstname,
      lastname: userData.lastname,
      roles: userData.roles,
    },
    {},
    { Authorization: `Bearer ${accessToken}` }
  );

// New functions for fetching reviews
export const getRegularReviews = (locationId, offset, limit, token) =>
  apiRequest(
    API_ENDPOINTS.LOCATION.REVIEWS.PAGINATED(locationId, offset, limit),
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const getGoogleReviews = async (
  locationId,
  offset,
  limit,
  token,
  startDate,
  endDate
) => {
  const params = { startDate, endDate, offset, limit };
  const cacheKey = createCacheKey(
    API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.PAGINATED(locationId, offset, limit),
    "GET",
    params
  );

  // Check if there's already an in-flight request
  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  // Create the promise for the API call
  const promise = apiRequest(
    API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.PAGINATED(locationId, offset, limit),
    "GET",
    null,
    params,
    { Authorization: `Bearer ${token}` }
  );

  // Store the promise in the cache
  requestCache.set(cacheKey, promise);

  try {
    const response = await promise;
    // Add error logging
    if (!response.data || response.data.length === 0) {

    }
    return response;
  } catch (error) {
    console.error("Error fetching reviews:", error);
    throw error;
  } finally {
    // Clean up the cache entry
    requestCache.delete(cacheKey);
  }
};

export const getReviewById = async (locationId, reviewId, token) => {
  return apiRequest(
    API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.BY_ID(locationId, reviewId),
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );
};

// Function to get a regular review by ID
export const getRegularReviewById = async (locationId, reviewId, token) => {
  return apiRequest(
    API_ENDPOINTS.LOCATION.REVIEWS.GET_BY_ID(locationId, reviewId),
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );
};

// Function to get all reviews (both regular and Google)
export const getAllReviews = async (locationId, offset, limit, token) => {
  try {
    const [regularReviews, googleReviews] = await Promise.all([
      getRegularReviews(locationId, offset, limit, token),
      getGoogleReviews(locationId, offset, limit, token),
    ]);

    if (
      regularReviews.status === "success" &&
      googleReviews.status === "success"
    ) {
      return {
        status: "success",
        data: [...regularReviews.data, ...googleReviews.data],
      };
    } else {
      throw new Error("Failed to fetch all reviews");
    }
  } catch (error) {
    console.error("Error fetching all reviews:", error);
    throw error;
  }
};

export const postReviewResponse = async (locationId, reviewId, accessToken) => {
  try {
    return await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.POST_RESPONSE(locationId, reviewId),
      "PUT",
      null,
      {},
      { Authorization: `Bearer ${accessToken}` }
    );
  } catch (error) {
    console.error("Error posting review response:", error);
    throw error;
  }
};

export const getStaffMentions = async (
  locationId,
  offset,
  limit,
  token,
  startDate,
  endDate
) => {
  const params = { startDate, endDate };
  const cacheKey = createCacheKey(
    API_ENDPOINTS.LOCATION.STAFF_MENTIONS.PAGINATED(locationId, offset, limit),
    "GET",
    params
  );

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    API_ENDPOINTS.LOCATION.STAFF_MENTIONS.PAGINATED(locationId, offset, limit),
    "GET",
    null,
    params,
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching staff mentions:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const getMenuInsights = async (
  locationId,
  offset,
  limit,
  token,
  startDate,
  endDate
) => {
  const params = { startDate, endDate };
  const cacheKey = createCacheKey(
    API_ENDPOINTS.LOCATION.MENU_INSIGHTS.PAGINATED(locationId, offset, limit),
    "GET",
    params
  );

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    API_ENDPOINTS.LOCATION.MENU_INSIGHTS.PAGINATED(locationId, offset, limit),
    "GET",
    null,
    params,
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching menu insights:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const updateReviewResponse = async (
  locationId,
  reviewId,
  response,
  token
) => {
  try {
    const result = await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.UPDATE_RESPONSE(
        locationId,
        reviewId
      ),
      "PUT",
      { response },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return result;
  } catch (error) {
    console.error("Error updating review response:", error);
    throw error;
  }
};

export const regenerateReviewResponseGPT = async (
  locationId,
  reviewId,
  token,
  additionalInstructions = ""
) => {
  try {
    const endpoint = API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.REGENERATE_RESPONSE(
      locationId,
      reviewId
    );

    const result = await apiRequest(
      endpoint,
      "POST",
      { additional_instructions: additionalInstructions },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return result;
  } catch (error) {
    console.error("Error regenerating review response:", error);
    console.error("Error details:", {
      status: error.response?.status,
      data: error.response?.data,
      url: error.config?.url,
    });
    throw error;
  }
};

export const createRestaurant = async (restaurantData, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.CREATE,
      "POST",
      {
        name: restaurantData.name,
        subscription_tier_id: restaurantData.subscription_tier_id,
      },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error creating restaurant:", error);
    throw error;
  }
};

export const updateRestaurant = async (id, restaurantData, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.UPDATE(id),
      "PUT",
      {
        name: restaurantData.name,
        subscription_tier_id: restaurantData.subscription_tier_id,
        weekly_report_day: restaurantData.weekly_report_day,
        main_open_ai_model: restaurantData.main_open_ai_model,
        integrations: restaurantData.integrations,
        approval_zapier_url: restaurantData.approval_zapier_url,
        additional_system_prompt_instructions:
          restaurantData.additional_system_prompt_instructions,
        auto_approve_reviews: restaurantData.auto_approve_reviews,
        auto_approval_minutes_delay: restaurantData.auto_approval_minutes_delay,
      },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error updating restaurant:", error);
    throw error;
  }
};

export const deleteRestaurant = async (id, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.DELETE(id),
      "DELETE",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error deleting restaurant:", error);
    throw error;
  }
};
export const updateRestaurantIntegrations = async (
  restaurantId,
  integrationData,
  token
) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.INTEGRATIONS(restaurantId),
      "PUT",
      integrationData,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error updating restaurant integrations:", error);
    throw error;
  }
};

export const approveReviewResponse = async (locationId, reviewId, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.LOCATION.GOOGLE_REVIEWS.APPROVE_RESPONSE(
        locationId,
        reviewId
      ),
      "PUT",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error approving review response:", error);
    throw error;
  }
};

export const deleteUser = async (userId, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.USER.DELETE(userId),
      "DELETE",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export const fetchUsers = async (token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.USERS,
      "GET",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const getRestaurant = (token) =>
  apiRequest(
    API_ENDPOINTS.RESTAURANT.ALL,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const createLocation = async (locationData, token) => {
  try {
    // Log the request data for debugging
    
    const response = await apiRequest(
      API_ENDPOINTS.LOCATION.CREATE,
      "POST",
      {
        name: locationData.name,
        restaurant_id: locationData.restaurant_id
      },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    // Enhanced error logging
    console.error("Error creating location:", {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      requestData: locationData
    });
    throw error;
  }
};

export const updateLocation = async (id, locationData, token) => {
  try {
    const response = await apiRequest(
      `/location/${id}`,
      "PUT",
      locationData,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error updating location:", error);
    throw error;
  }
};

export const deleteLocation = async (id, token) => {
  try {
    const response = await apiRequest(
      `/location/${id}`,
      "DELETE",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error deleting location:", error);
    throw error;
  }
};
// Libro API

let libroJWT = null;

const getLibroJWT = async () => {
  try {
    const email = process.env.REACT_APP_LIBRO_EMAIL;
    const token = process.env.REACT_APP_LIBRO_TOKEN;

    if (!email || !token) {
      throw new Error("Libro credentials are not set in environment variables");
    }

    const response = await axios.post(
      getLibroApiUrl(LIBRO_ENDPOINTS.AUTHENTICATION),
      { email, token },
      { headers: LIBRO_API_HEADERS }
    );


    // Ensure you are correctly accessing the 'token' field
    if (response.data && response.data.token) {
      libroJWT = response.data.token;
      return libroJWT;
    } else {
      throw new Error("JWT not found in authentication response");
    }
  } catch (error) {
    console.error(
      "Error authenticating with Libro:",
      error.response ? error.response.data : error.message
    );
    console.error("Full error object:", error);
    throw error;
  }
};

export const fetchLibroReservations = async (params = {}) => {
  try {
    const jwt = await getLibroJWT();
    const response = await axios.get(getLibroApiUrl(LIBRO_ENDPOINTS.BOOKINGS), {
      params: {
        limit: 50,
        offset: params.offset || 0,
        include: "person", // Add this line to include person data
        ...params,
      },
      headers: {
        ...LIBRO_API_HEADERS,
        Authorization: `JWT ${jwt}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching Libro reservations:",
      error.response ? error.response.data : error.message
    );
    console.error("Full error object:", error);
    throw error;
  }
};

export const testLibroAuthentication = async () => {
  try {
    const jwt = await getLibroJWT();
    return true;
  } catch (error) {
    console.error(
      "Authentication failed:",
      error.response ? error.response.data : error.message
    );
    console.error("Full error object:", error);
    throw error;
  }
};

export const fetchLibroBookingDetails = async (bookingId) => {
  try {
    const jwt = await getLibroJWT();
    const response = await axios.get(
      getLibroApiUrl(LIBRO_ENDPOINTS.BOOKING(bookingId)),
      {
        headers: {
          ...LIBRO_API_HEADERS,
          Authorization: `JWT ${jwt}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching Libro booking details for ID ${bookingId}:`,
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

// Report functions
export const getRestaurantReports = (restaurantId, token) =>
  apiRequest(
    API_ENDPOINTS.RESTAURANT.REPORTS.GET(restaurantId),
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const createStaffReport = (restaurantId, dateRange, token) =>
  apiRequest(
    API_ENDPOINTS.RESTAURANT.REPORTS.CREATE_STAFF(restaurantId),
    "POST",
    dateRange,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const createMenuReport = (restaurantId, dateRange, token) =>
  apiRequest(
    API_ENDPOINTS.RESTAURANT.REPORTS.CREATE_MENU(restaurantId),
    "POST",
    dateRange,
    {},
    { Authorization: `Bearer ${token}` }
  );

// Subscription tier functions
export const getSubscriptionTiers = (token) =>
  apiRequest(
    API_ENDPOINTS.SUBSCRIPTION_TIER.ALL,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const createSubscriptionTier = (tierData, token) =>
  apiRequest(
    API_ENDPOINTS.SUBSCRIPTION_TIER.CREATE,
    "POST",
    tierData,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const updateSubscriptionTier = (tierId, tierData, token) =>
  apiRequest(
    API_ENDPOINTS.SUBSCRIPTION_TIER.UPDATE(tierId),
    "PUT",
    tierData,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const deleteSubscriptionTier = (tierId, token) =>
  apiRequest(
    API_ENDPOINTS.SUBSCRIPTION_TIER.DELETE(tierId),
    "DELETE",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

export const getAllReports = async (
  restaurantId,
  offset,
  limit,
  token,
  dateFilter,
  reportType
) => {
  const params = {
    offset,
    limit,
  };

  if (dateFilter?.startDate && dateFilter?.endDate) {
    params.start = dateFilter.startDate;
    params.end = dateFilter.endDate;
  }

  if (reportType) {
    params.type = reportType;
  }

  return apiRequest(
    API_ENDPOINTS.RESTAURANT.REPORTS.PAGINATED(restaurantId, offset, limit),
    "GET",
    null,
    params,
    { Authorization: `Bearer ${token}` }
  );
};

export const generateReport = async (
  restaurantId,
  reportType,
  dateFilter,
  token
) => {
  const endpoint =
    reportType === "staff_report"
      ? API_ENDPOINTS.RESTAURANT.REPORTS.CREATE_STAFF(restaurantId)
      : API_ENDPOINTS.RESTAURANT.REPORTS.CREATE_MENU(restaurantId);

  const payload = {
    start: new Date(dateFilter.startDate).toISOString(),
    end: new Date(dateFilter.endDate).toISOString(),
  };

  return apiRequest(
    endpoint,
    "POST",
    payload,
    {},
    { Authorization: `Bearer ${token}` }
  );
};

export const deleteReport = async (restaurantId, reportId, token) => {
  const endpoint = API_ENDPOINTS.RESTAURANT.REPORTS.DELETE(
    restaurantId,
    reportId
  );
  return apiRequest(
    endpoint,
    "DELETE",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );
};

// Get all unseen alerts for the connected user
export const getUserAlerts = async (restaurantId, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.ALERTS.GET(restaurantId),
      "GET",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error fetching user alerts:", error);
    throw error;
  }
};

// Get alert configurations for all users (Super Admin only)
export const getAllAlertConfigs = async (restaurantId, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.ALERTS.CONFIG.ALL(restaurantId),
      "GET",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error fetching all alert configurations:", error);
    throw error;
  }
};

// Get alert configuration for the connected user
export const getSelfAlertConfig = async (restaurantId, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.ALERTS.CONFIG.SELF(restaurantId),
      "GET",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error fetching self alert configuration:", error);
    throw error;
  }
};

// Update alert configuration for the connected user
export const updateAlertConfig = async (restaurantId, configData, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.ALERTS.CONFIG.UPDATE(restaurantId),
      "PUT",
      {
        email: configData.email,
        client_alerts_enabled: configData.client_alerts_enabled,
        email_alerts_enabled: configData.email_alerts_enabled,
      },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error updating alert configuration:", error);
    throw error;
  }
};

// Mark a single alert as seen
export const markAlertAsSeen = async (restaurantId, reviewId, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.ALERTS.BAD_REVIEW.MARK_SEEN(restaurantId, reviewId),
      "PUT",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error marking alert as seen:", error);
    throw error;
  }
};

// Mark multiple alerts as seen
export const markManyAlertsAsSeen = async (restaurantId, reviewIds, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.ALERTS.BAD_REVIEW.MARK_MANY_SEEN(restaurantId),
      "PUT",
      { reviews: reviewIds },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error marking multiple alerts as seen:", error);
    throw error;
  }
};

export const getStaffMentionProfiles = async (
  locationId,
  token,
  { dateFilter, limit = 100, offset = 0 }
) => {
  const params = {
    startDate: dateFilter?.startDate,
    endDate: dateFilter?.endDate,
    limit,
    offset,
  };

  const endpoint =
    API_ENDPOINTS.LOCATION.STAFF_MENTIONS.PROFILES.GET(locationId);
  const cacheKey = createCacheKey(endpoint, "GET", params);

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(endpoint, "GET", null, params, {
    Authorization: `Bearer ${token}`,
  });

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching staff mention profiles:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const getStaffPerformance = async (
  locationId,
  startDate,
  endDate,
  token
) => {
  const cacheKey = createCacheKey(
    API_ENDPOINTS.LOCATION.STAFF_MENTIONS.PERFORMANCE.BY_LOCATION(
      locationId,
      startDate,
      endDate
    ),
    "GET",
    {}
  );

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    API_ENDPOINTS.LOCATION.STAFF_MENTIONS.PERFORMANCE.BY_LOCATION(
      locationId,
      startDate,
      endDate
    ),
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching staff performance:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const getRestaurantStaffPerformance = async (
  restaurantId,
  startDate,
  endDate,
  token
) => {
  const cacheKey = createCacheKey(
    API_ENDPOINTS.LOCATION.STAFF_MENTIONS.PERFORMANCE.BY_RESTAURANT(
      restaurantId,
      startDate,
      endDate
    ),
    "GET",
    {}
  );

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    API_ENDPOINTS.LOCATION.STAFF_MENTIONS.PERFORMANCE.BY_RESTAURANT(
      restaurantId,
      startDate,
      endDate
    ),
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching restaurant staff performance:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const getKeywordOccurrences = async (
  locationId,
  token,
  startDate,
  endDate
) => {
  const params = { startDate, endDate };
  const cacheKey = createCacheKey(
    API_ENDPOINTS.LOCATION.KEYWORDS.OCCURRENCES(locationId),
    "GET",
    params
  );

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    API_ENDPOINTS.LOCATION.KEYWORDS.OCCURRENCES(locationId),
    "GET",
    null,
    params,
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    const response = await promise;
    if (!response.data || response.data.length === 0) {

    }
    return response;
  } catch (error) {
    console.error("Error fetching keyword occurrences:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

// Location Keywords
export const getLocationKeywordOccurrences = async (
  locationId,
  token,
  { offset, limit, startDate, endDate } = {}
) => {
  const params = { startDate, endDate };
  const endpoint = API_ENDPOINTS.KEYWORDS.LOCATION.ALL_OCCURRENCES(
    locationId,
    offset,
    limit
  );
  const cacheKey = createCacheKey(endpoint, "GET", params);

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(endpoint, "GET", null, params, {
    Authorization: `Bearer ${token}`,
  });

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching location keyword occurrences:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const getLocationKeywordsByCategory = async (
  locationId,
  category,
  token,
  { offset, limit, startDate, endDate } = {}
) => {
  const params = { startDate, endDate };
  const endpoint = API_ENDPOINTS.KEYWORDS.LOCATION.CATEGORY_OCCURRENCES(
    locationId,
    category,
    offset,
    limit
  );
  const cacheKey = createCacheKey(endpoint, "GET", params);

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(endpoint, "GET", null, params, {
    Authorization: `Bearer ${token}`,
  });

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching location keywords by category:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const getLocationKeywordDetails = async (locationId, keyword, token) => {
  const endpoint = API_ENDPOINTS.KEYWORDS.LOCATION.KEYWORD_DETAILS(
    locationId,
    keyword
  );
  const cacheKey = createCacheKey(endpoint, "GET", {});

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    endpoint,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching location keyword details:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

// Restaurant Keywords
export const getRestaurantKeywordOccurrences = async (
  restaurantId,
  token,
  { offset, limit, startDate, endDate } = {}
) => {
  const params = { startDate, endDate };
  const endpoint = API_ENDPOINTS.KEYWORDS.RESTAURANT.ALL_OCCURRENCES(
    restaurantId,
    offset,
    limit
  );
  const cacheKey = createCacheKey(endpoint, "GET", params);

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(endpoint, "GET", null, params, {
    Authorization: `Bearer ${token}`,
  });

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching restaurant keyword occurrences:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const getRestaurantKeywordsByCategory = async (
  restaurantId,
  category,
  token,
  { offset, limit, startDate, endDate } = {}
) => {
  const params = { startDate, endDate };
  const endpoint = API_ENDPOINTS.KEYWORDS.RESTAURANT.CATEGORY_OCCURRENCES(
    restaurantId,
    category,
    offset,
    limit
  );
  const cacheKey = createCacheKey(endpoint, "GET", params);

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(endpoint, "GET", null, params, {
    Authorization: `Bearer ${token}`,
  });

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching restaurant keywords by category:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const getRestaurantKeywordDetails = async (
  restaurantId,
  keyword,
  token
) => {
  const endpoint = API_ENDPOINTS.KEYWORDS.RESTAURANT.KEYWORD_DETAILS(
    restaurantId,
    keyword
  );
  const cacheKey = createCacheKey(endpoint, "GET", {});

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    endpoint,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching restaurant keyword details:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

// Get restaurant details for authenticated user
export const getSelfRestaurant = async (token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.SELF,
      "GET",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error fetching self restaurant:", error);
    throw error;
  }
};

// Update additional instructions for AI system prompt
export const updateAdditionalInstructions = async (
  restaurantId,
  instructions,
  token
) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.ADDITIONAL_INSTRUCTIONS(restaurantId),
      "PUT",
      { additional_system_prompt_instructions: instructions },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error updating additional instructions:", error);
    throw error;
  }
};

// Update auto-approval settings for a restaurant
export const updateAutoApprovalSettings = async (
  restaurantId,
  settings,
  token
) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.RESTAURANT.AUTO_APPROVAL(restaurantId),
      "PUT",
      {
        auto_approve_reviews: settings.auto_approve_reviews,
        auto_approval_minutes_delay: settings.auto_approval_minutes_delay,
      },
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error updating auto-approval settings:", error);
    throw error;
  }
};

// Location Staff Roles
export const getLocationStaffRoles = async (locationId, token) => {
  const endpoint = API_ENDPOINTS.LOCATION.STAFF_ROLES.GET(locationId);
  const cacheKey = createCacheKey(endpoint, "GET", {});

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    endpoint,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching location staff roles:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const getPaginatedLocationStaffRoles = async (
  locationId,
  offset,
  limit,
  token
) => {
  const endpoint = API_ENDPOINTS.LOCATION.STAFF_ROLES.PAGINATED(
    locationId,
    offset,
    limit
  );
  const cacheKey = createCacheKey(endpoint, "GET", {});

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    endpoint,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching paginated location staff roles:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const deleteLocationStaffRole = async (locationId, roleId, token) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.LOCATION.STAFF_ROLES.DELETE(locationId, roleId),
      "DELETE",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
    return response;
  } catch (error) {
    console.error("Error deleting location staff role:", error);
    throw error;
  }
};

// Restaurant Staff Roles
export const getRestaurantStaffRoles = async (restaurantId, token) => {
  const endpoint = API_ENDPOINTS.RESTAURANT.STAFF_ROLES.GET(restaurantId);
  const cacheKey = createCacheKey(endpoint, "GET", {});

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    endpoint,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching restaurant staff roles:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const getPaginatedRestaurantStaffRoles = async (
  restaurantId,
  offset,
  limit,
  token
) => {
  const endpoint = API_ENDPOINTS.RESTAURANT.STAFF_ROLES.PAGINATED(
    restaurantId,
    offset,
    limit
  );
  const cacheKey = createCacheKey(endpoint, "GET", {});

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    endpoint,
    "GET",
    null,
    {},
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching paginated restaurant staff roles:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

// Onboarding form submission
export const submitOnboardingForm = async (formData) => {
  try {
    const response = await apiRequest(
      API_ENDPOINTS.ONBOARDING,
      "POST",
      formData,
      {},
      { "Content-Type": "application/json" }
    );
    return response;
  } catch (error) {
    console.error("Error submitting onboarding form:", error);
    throw error;
  }
};

// Yelp Reviews
export const getYelpReviews = async (locationId, offset, limit, token) => {
  const params = { offset, limit };
  const cacheKey = createCacheKey(
    API_ENDPOINTS.LOCATION.YELP_REVIEWS.PAGINATED(locationId, offset, limit),
    "GET",
    params
  );

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    API_ENDPOINTS.LOCATION.YELP_REVIEWS.PAGINATED(locationId, offset, limit),
    "GET",
    null,
    params,
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching Yelp reviews:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const createYelpReview = async (locationId, reviewData, token) => {
  try {
    return await apiRequest(
      API_ENDPOINTS.LOCATION.YELP_REVIEWS.POST(locationId),
      "POST",
      reviewData,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error("Error creating Yelp review:", error);
    throw error;
  }
};

// TripAdvisor Reviews
export const getTripAdvisorReviews = async (locationId, offset, limit, token) => {
  const params = { offset, limit };
  const cacheKey = createCacheKey(
    API_ENDPOINTS.LOCATION.TRIP_ADVISOR_REVIEWS.PAGINATED(locationId, offset, limit),
    "GET",
    params
  );

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    API_ENDPOINTS.LOCATION.TRIP_ADVISOR_REVIEWS.PAGINATED(locationId, offset, limit),
    "GET",
    null,
    params,
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching TripAdvisor reviews:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const createTripAdvisorReview = async (locationId, reviewData, token) => {
  try {
    return await apiRequest(
      API_ENDPOINTS.LOCATION.TRIP_ADVISOR_REVIEWS.POST(locationId),
      "POST",
      reviewData,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error("Error creating TripAdvisor review:", error);
    throw error;
  }
};

// Social Media Comments
export const getSocialMediaComments = async (restaurantId, offset, limit, token) => {
  const params = { offset, limit };
  const cacheKey = createCacheKey(
    API_ENDPOINTS.RESTAURANT.SOCIAL_MEDIA_COMMENTS.PAGINATED(restaurantId, offset, limit),
    "GET",
    params
  );

  if (requestCache.has(cacheKey)) {
    return requestCache.get(cacheKey);
  }

  const promise = apiRequest(
    API_ENDPOINTS.RESTAURANT.SOCIAL_MEDIA_COMMENTS.PAGINATED(restaurantId, offset, limit),
    "GET",
    null,
    params,
    { Authorization: `Bearer ${token}` }
  );

  requestCache.set(cacheKey, promise);

  try {
    return await promise;
  } catch (error) {
    console.error("Error fetching social media comments:", error);
    throw error;
  } finally {
    requestCache.delete(cacheKey);
  }
};

export const createSocialMediaComment = async (restaurantId, commentData, token) => {
  try {
    return await apiRequest(
      API_ENDPOINTS.RESTAURANT.SOCIAL_MEDIA_COMMENTS.POST(restaurantId),
      "POST",
      commentData,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error("Error creating social media comment:", error);
    throw error;
  }
};

export const deleteSocialMediaComment = async (restaurantId, commentId, token) => {
  try {
    return await apiRequest(
      API_ENDPOINTS.RESTAURANT.SOCIAL_MEDIA_COMMENTS.DELETE(restaurantId, commentId),
      "DELETE",
      null,
      {},
      { Authorization: `Bearer ${token}` }
    );
  } catch (error) {
    console.error("Error deleting social media comment:", error);
    throw error;
  }
};

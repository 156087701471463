import React from 'react';
import { Globe } from 'lucide-react';
import { useLanguage } from '../hooks/useLanguageHook';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
} from '../../../components/shadcn/dropdown/DropdownMenu';
import { useNavigate, useLocation } from 'react-router-dom';

const LanguageSelector = () => {
  const { language, changeLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelectLanguage = (lang) => {
    if (lang !== language) {
      // Persist the selected language locally.
      localStorage.setItem('preferredLang', lang);
      // Optionally update the <html> lang attribute for accessibility and SEO
      document.documentElement.lang = lang;
      // Trigger the language change to load new translations
      changeLanguage(lang);
      // Update the URL by replacing the language prefix in the current path.
      const pathWithoutLang = location.pathname.replace(/^\/(en|fr)/, '');
      navigate(`/${lang}${pathWithoutLang}`, { replace: true });
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="inline-flex items-center gap-2 rounded-md border border-gray-200 px-2 py-1 focus:outline-none">
          <Globe className="w-5 h-5" />
          <span className="hidden sm:inline">{language.toUpperCase()}</span>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent align="end" className="w-32">
          <DropdownMenuItem onClick={() => handleSelectLanguage('en')}>
            English
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleSelectLanguage('fr')}>
            Français
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
};

export default LanguageSelector;

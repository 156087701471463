import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { submitOnboardingForm } from '../../services/api/apiUtility';

// Create async thunk for submitting onboarding data
export const submitOnboarding = createAsyncThunk(
  'onboarding/submit',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await submitOnboardingForm(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to submit onboarding data');
    }
  }
);

const initialState = {
  isSubmitting: false,
  submitSuccess: false,
  error: null,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    resetOnboardingState: (state) => {
      state.isSubmitting = false;
      state.submitSuccess = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitOnboarding.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
      })
      .addCase(submitOnboarding.fulfilled, (state) => {
        state.isSubmitting = false;
        state.submitSuccess = true;
      })
      .addCase(submitOnboarding.rejected, (state, action) => {
        state.isSubmitting = false;
        state.error = action.payload;
      });
  },
});

export const { resetOnboardingState } = onboardingSlice.actions;
export default onboardingSlice.reducer; 